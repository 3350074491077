import React from 'react';
import {
    List,
    Box,
    ListItemText,
    ListItem,
    CircularProgress,
    TextField,
    Typography,
    Button,
    Modal,
    InputAdornment,
    withStyles,
    IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PropTypes from 'prop-types';

const StyledListItem = withStyles({
    root: {
        '&.Mui-selected': {
            backgroundColor: '#01A19C',
        },
    },
})(ListItem);

const View = (props) => {
    const {
        listUser,
        handelSelectUser,
        userSeleted,
        isLoading,
        handleNotification,
        notification,
        handleSendNotification,
        openModalLicense,
        setOpenModalLicense,
        search,
        handleSearch,
        handleSelectVehicle,
    } = props;

    const ListUser = () => (
        <Box style={{ width: '25%' }}>
            <TextField
                style={{ margin: 15 }}
                label='Search User'
                variant='outlined'
                value={search}
                onChange={handleSearch}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <div style={{ overflow: 'auto', maxHeight: 440 }}>
                <List component='nav'>
                    {listUser.map((user, index) => {
                        return (
                            <StyledListItem
                                button
                                key={index}
                                onClick={() => {
                                    handelSelectUser(user);
                                }}
                                style={{ borderBottom: '1px solid #c6bdbd' }}
                                selected={user._id == userSeleted._id}
                            >
                                <ListItemText
                                    primary={`${user.first_name} ${user.last_name}`}
                                    secondary={user.email}
                                />
                            </StyledListItem>
                        );
                    })}
                </List>
            </div>
        </Box>
    );

    if (!userSeleted._id) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {ListUser()}
                <div
                    style={{
                        display: 'grid',
                        justifyItems: 'center',
                        alignItems: 'center',
                        height: 480,
                        flex: 1,
                    }}
                >
                    <Typography variant='h3' display='block' gutterBottom style={{ flex: 1 }}>
                        SELECT A USER
                    </Typography>
                </div>
            </div>
        );
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            {ListUser()}
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    height: 480,
                }}
            >
                {isLoading ? (
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'row',
                                height: 240,
                            }}
                        >
                            <Box style={{ flex: 1, margin: 10 }}>
                                <Typography
                                    variant='h6'
                                    display='block'
                                    gutterBottom
                                    style={{ flex: 1 }}
                                >
                                    USER DATA
                                </Typography>
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        display: 'flex',
                                    }}
                                >
                                    <Typography variant='subtitle2' display='block' gutterBottom>
                                        Client:
                                    </Typography>
                                    <Typography variant='subtitle1' display='block' gutterBottom>
                                        {userSeleted.first_name} {userSeleted.last_name}
                                    </Typography>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        display: 'flex',
                                    }}
                                >
                                    <Typography variant='subtitle2' display='block' gutterBottom>
                                        Email:
                                    </Typography>
                                    <Typography variant='subtitle1' display='block' gutterBottom>
                                        {userSeleted.email}
                                    </Typography>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        display: 'flex',
                                    }}
                                >
                                    <Typography variant='subtitle2' display='block' gutterBottom>
                                        Phone:
                                    </Typography>
                                    <Typography variant='subtitle1' display='block' gutterBottom>
                                        {userSeleted.phone_number}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={() => {
                                            setOpenModalLicense({
                                                open: true,
                                                img: userSeleted.driver_license_img,
                                            });
                                        }}
                                    >
                                        License ID
                                    </Button>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={() => {
                                            setOpenModalLicense({
                                                open: true,
                                                img: userSeleted.insurance_id_img,
                                            });
                                        }}
                                    >
                                        Insurance
                                    </Button>
                                </div>
                            </Box>
                            <Box style={{ flex: 1, margin: 10 }}>
                                <Typography
                                    variant='h6'
                                    display='block'
                                    gutterBottom
                                    style={{ flex: 1 }}
                                >
                                    NOTIFICATION
                                </Typography>
                                <TextField
                                    label='Message'
                                    multiline
                                    rows={4}
                                    variant='outlined'
                                    value={notification.message}
                                    onChange={handleNotification}
                                />
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={handleSendNotification}
                                >
                                    Send
                                </Button>
                            </Box>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flex: 1,
                                height: 240,
                            }}
                        >
                            <Box style={{ flex: 1, margin: 10 }}>
                                <Typography
                                    variant='h6'
                                    display='block'
                                    gutterBottom
                                    style={{ flex: 1 }}
                                >
                                    LIST VEHICLES
                                </Typography>
                                <div style={{ overflow: 'auto', maxHeight: 230 }}>
                                    <List component='nav'>
                                        {userSeleted.listVehicle.map((vehicle, index) => {
                                            return (
                                                <StyledListItem
                                                    button
                                                    key={index}
                                                    alignItems='flex-start'
                                                    selected={vehicle._id == userSeleted.idVehicle}
                                                    onClick={() => {
                                                        handleSelectVehicle(vehicle._id, false);
                                                    }}
                                                    style={{
                                                        flexDirection: 'column',
                                                        borderBottom: '1px solid #c6bdbd',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                            }}
                                                        >
                                                            <Typography
                                                                variant='subtitle2'
                                                                display='block'
                                                                gutterBottom
                                                            >
                                                                VIN:
                                                            </Typography>
                                                            <Typography
                                                                variant='subtitle2'
                                                                display='block'
                                                                gutterBottom
                                                            >
                                                                {vehicle.vin}
                                                            </Typography>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                            }}
                                                        >
                                                            <Typography
                                                                variant='subtitle2'
                                                                display='block'
                                                                gutterBottom
                                                            >
                                                                Year:
                                                            </Typography>
                                                            <Typography
                                                                variant='subtitle2'
                                                                display='block'
                                                                gutterBottom
                                                            >
                                                                {vehicle.year}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                            }}
                                                        >
                                                            <Typography
                                                                variant='subtitle2'
                                                                display='block'
                                                                gutterBottom
                                                            >
                                                                Make:
                                                            </Typography>
                                                            <Typography
                                                                variant='subtitle2'
                                                                display='block'
                                                                gutterBottom
                                                            >
                                                                {vehicle.make}
                                                            </Typography>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                            }}
                                                        >
                                                            <Typography
                                                                variant='subtitle2'
                                                                display='block'
                                                                gutterBottom
                                                            >
                                                                Model:
                                                            </Typography>
                                                            <Typography
                                                                variant='subtitle2'
                                                                display='block'
                                                                gutterBottom
                                                            >
                                                                {vehicle.model}
                                                            </Typography>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                            }}
                                                        >
                                                            <Typography
                                                                variant='subtitle2'
                                                                display='block'
                                                                gutterBottom
                                                            >
                                                                Color:
                                                            </Typography>
                                                            <Typography
                                                                variant='subtitle2'
                                                                display='block'
                                                                gutterBottom
                                                            >
                                                                {vehicle.color}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </StyledListItem>
                                            );
                                        })}
                                    </List>
                                </div>
                            </Box>
                            <Box style={{ flex: 1, margin: 10 }}>
                                <Typography
                                    variant='h6'
                                    display='block'
                                    gutterBottom
                                    style={{ flex: 1 }}
                                >
                                    LIST APPOINTMENT
                                </Typography>
                                <div style={{ overflow: 'auto', maxHeight: 230 }}>
                                    <List component='nav'>
                                        {userSeleted.listappointment.map((appointment, index) => {
                                            return (
                                                <StyledListItem
                                                    button
                                                    key={index}
                                                    alignItems='flex-start'
                                                    onClick={() => {
                                                        handleSelectVehicle(
                                                            appointment.vehicle_id,
                                                            appointment._id
                                                        );
                                                    }}
                                                    selected={
                                                        userSeleted.appointmentId
                                                            ? appointment.vehicle_id ==
                                                                  userSeleted.idVehicle &&
                                                              userSeleted.appointmentId ==
                                                                  appointment._id
                                                            : appointment.vehicle_id ==
                                                              userSeleted.idVehicle
                                                    }
                                                    style={{
                                                        flexDirection: 'column',
                                                        borderBottom: '1px solid #c6bdbd',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                            }}
                                                        >
                                                            <Typography
                                                                variant='subtitle2'
                                                                display='block'
                                                                gutterBottom
                                                            >
                                                                Date:
                                                            </Typography>
                                                            <Typography
                                                                variant='subtitle2'
                                                                display='block'
                                                                gutterBottom
                                                            >
                                                                {appointment.pickup_date}
                                                            </Typography>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                            }}
                                                        >
                                                            <Typography
                                                                variant='subtitle2'
                                                                display='block'
                                                                gutterBottom
                                                            >
                                                                Time:
                                                            </Typography>
                                                            <Typography
                                                                variant='subtitle2'
                                                                display='block'
                                                                gutterBottom
                                                            >
                                                                {appointment.pickup_time}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <Typography
                                                            variant='subtitle2'
                                                            display='block'
                                                            gutterBottom
                                                        >
                                                            Address:
                                                        </Typography>
                                                        <Typography
                                                            variant='subtitle2'
                                                            display='block'
                                                            gutterBottom
                                                        >
                                                            {appointment.pickup_address.street},{' '}
                                                            {appointment.pickup_address.street_2},{' '}
                                                            {appointment.pickup_address.state},{' '}
                                                            {appointment.pickup_address.city},{' '}
                                                            {appointment.pickup_address.zip}
                                                        </Typography>
                                                    </div>
                                                </StyledListItem>
                                            );
                                        })}
                                    </List>
                                </div>
                            </Box>
                        </div>
                    </>
                )}
            </div>
            <Modal
                open={openModalLicense.open}
                onClose={() => setOpenModalLicense({ open: false, img: '' })}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <>
                    <IconButton
                        style={{ position: 'absolute', top: 5, right: 8 }}
                        onClick={() => setOpenModalLicense({ open: false, img: '' })}
                    >
                        <HighlightOffIcon style={{ color: 'white', fontSize: 40 }} />
                    </IconButton>
                    <img
                        src={`data:image/jpg;base64,${openModalLicense.img}`}
                        alt='License ID'
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            resizeMode: 'stretch',
                        }}
                    />
                </>
            </Modal>
        </div>
    );
};

View.propTypes = {
    listUser: PropTypes.array.isRequired,
    handelSelectUser: PropTypes.func.isRequired,
    userSeleted: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    openModalLicense: PropTypes.bool.isRequired,
    handleNotification: PropTypes.func.isRequired,
    notification: PropTypes.object.isRequired,
    handleSendNotification: PropTypes.func.isRequired,
    setOpenModalLicense: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
    handleSearch: PropTypes.func.isRequired,
    handleSelectVehicle: PropTypes.func.isRequired,
};
View.defaultProps = {};
export default View;
