import { call, put, select, takeLatest } from 'redux-saga/effects';

import { isLoadingAppAction } from '../actions/utilityAction';
import { isLoadingUserDataAction } from '../actions/userAction';
import { snackbarAction } from '../actions/utilityAction';
import { apiCall } from '../http/apiCall';
import { tokenSelector } from '../selector';
import { VEHICLE, VEHICLE_BY_USER } from '../../utility/urls';
import {
    FETCH_VEHICLE_BY_ID_START,
    FETCH_VEHICLE_BY_ID_COMPLETE,
    GET_USER_VEHICLE_LIST_COMPLETE,
    GET_USER_VEHICLE_LIST_START,
} from '../actionType';
import { throwError } from '../../utility/error';

function* getUserVehicleList({ id }) {
    yield put(isLoadingUserDataAction(true)); // for the user selecting only
    try {
        const token = yield select(tokenSelector);
        const vehiclesResult = yield call(apiCall, `${VEHICLE_BY_USER}/${id}`, null, token, 'GET');

        yield put({ type: GET_USER_VEHICLE_LIST_COMPLETE, userVehicleList: vehiclesResult.data });
    } catch (error) {
        yield put(throwError(error));
    }
}

function* fetchVehicleById({ vehicleId }) {
    yield put(isLoadingAppAction(true));

    try {
        const token = yield select(tokenSelector);
        const result = yield call(apiCall, `${VEHICLE}/${vehicleId}`, null, token, 'GET');

        yield put({ type: FETCH_VEHICLE_BY_ID_COMPLETE, singleVehicle: result.data });
    } catch (error) {
        yield put(throwError(error));
    }
    yield put(isLoadingAppAction(false));
}

export default function* vehicleWatch() {
    yield takeLatest(GET_USER_VEHICLE_LIST_START, getUserVehicleList);
    yield takeLatest(FETCH_VEHICLE_BY_ID_START, fetchVehicleById);
}
