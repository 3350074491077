export const BASE_URL = 'https://api.nextcarservices.com/';
// export const BASE_URL = 'http://127.0.0.1:5002';

export const FORGOT_PASSWORD = 'forgot_password';
export const FORGOT_PASSWORD_v2 = 'v2/forgot_password';
export const RESET_PASSWORD_v2 = 'v2/reset_password';
export const VEHICLE = 'vehicle';
export const PROTECTED = 'protected';
export const VEHICLE_BY_USER = 'vehicle_by_user';
export const APPOINTMENT = 'appointment';
export const CHANGE_PASSWORD = 'change_password';
export const APPOINTMENT_BY_USER = 'appointment_by_user';
export const AUTH = 'auth';
export const USER = 'user';
export const USER_DRIVER_LICENSE = 'user_driver_license';
export const NOTIFICATIONS_ONE_USER = 'notifications_one_user';
export const SCHEDULE_SERVICE_FOR_IFRAME = 'scheduleserviceforiframe';
//https://expo.io/@dezbrid/Next
//http://hdupturn.com:5002/
///https://www.nextcarservices.com/
