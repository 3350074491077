import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { apiCall } from '../http/apiCall';
import { AUTH, FORGOT_PASSWORD_v2, RESET_PASSWORD_v2 } from '../../utility/urls';
import {
    LOGIN_START,
    LOGIN_COMPLETE,
    LOGOUT_COMPLETE,
    LOGOUT_START,
    FORGOT_PASSWORD_USER,
    RESET_PASSWORD,
    IS_RESET_TOKEN_VALID,
    RESET_PASSWORD_SNACKBAR,
} from '../actionType';
import { snackbarAction, isLoadingAppAction } from '../actions/utilityAction';
import { setLocalStorage, rmLocalStorage, REFRESH_TOKEN } from '../../utility/localStorage';
import { throwError } from '../../utility/error';

function* authUser({ userData }) {
    yield put(isLoadingAppAction(true));
    try {
        const result = yield call(apiCall, AUTH, userData, null, 'POST');
        const data = result.data;

        yield put({ type: LOGIN_COMPLETE, token: data.access_token });
        yield setLocalStorage(data.access_token);
        yield setLocalStorage(data.refresh_token, REFRESH_TOKEN);
    } catch (error) {
        yield put(throwError(error));
    }
    yield put(isLoadingAppAction(false));
}

function* logout() {
    yield rmLocalStorage();
    yield put({ type: LOGOUT_COMPLETE });
}

function* forgotPassword({ email }) {
    yield put(isLoadingAppAction(true));

    try {
        const result = yield call(apiCall, FORGOT_PASSWORD_v2, email, null, 'POST');

        yield put(
            snackbarAction({
                open: true,
                message: result.data.message,
                type: 'success',
            })
        );

        setTimeout(() => {
            window.location.href = '/sign-in';
        }, 8000);
    } catch (error) {
        yield put(throwError(error));
    }
    yield put(isLoadingAppAction(false));
}

function* resetPassword({ data, method, reqForNextDriver }) {
    yield put(isLoadingAppAction(true));
    try {
        let resetResult;

        if (reqForNextDriver === 'next_drivers') {
            const URI = 'http://drivers.nextcarservices.com/reset_password';

            const resetURL = method === 'GET' ? `${URI}/${data}` : URI;
            resetResult = yield call(
                apiCall,
                null,
                method === 'POST' && data,
                null,
                method,
                null,
                null,
                resetURL
            );
        } else {
            const resetURL = method === 'GET' ? `${RESET_PASSWORD_v2}/${data}` : RESET_PASSWORD_v2;
            resetResult = yield call(apiCall, resetURL, method === 'POST' && data, null, method);
        }

        method === 'GET' && (yield put({ type: IS_RESET_TOKEN_VALID, isResetTokenValid: true }));

        if (method === 'POST') {
            yield put(
                snackbarAction({
                    open: true,
                    type: 'success',
                    message: resetResult.data?.message,
                })
            );

            yield put({
                type: RESET_PASSWORD_SNACKBAR,
                resetSnackbar: { open: true, type: 'success', message: resetResult.data?.message },
            });

            yield put({ type: IS_RESET_TOKEN_VALID, isResetTokenValid: false });
        }
    } catch (error) {
        yield put({
            type: RESET_PASSWORD_SNACKBAR,
            resetSnackbar: { open: true, type: 'error', message: error.response?.data?.message },
        });
        yield put(throwError(error));
    }
    yield put(isLoadingAppAction(false));
}

export default function* loginWatch() {
    yield takeLatest(LOGIN_START, authUser);
    yield takeLatest(LOGOUT_START, logout);
    yield takeLatest(FORGOT_PASSWORD_USER, forgotPassword);
    yield takeLatest(RESET_PASSWORD, resetPassword);
}
