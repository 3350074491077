import React from 'react';
import { Typography, makeStyles, Box } from '@material-ui/core/';
import PropTypes from 'prop-types';
const useStyles = makeStyles((theme) => ({
    typography: {
        marginTop: ({ marginTop }) => marginTop,
        fontWeight: ({ fontWeight }) => fontWeight || 'normal',
        fontFamily: 'ToyotaType',
        color: ({ color }) => color || '#616161',
        lineHeight: '24px',
    },
}));

export const Paragraph = ({ children, bold, marginTop, fontWeight, color }) => {
    const classes = useStyles({ bold, marginTop, fontWeight, color });

    return (
        <Typography variant='subtitle1' gutterBottom className={classes.typography}>
            {children}
        </Typography>
    );
};

Paragraph.propTypes = {
    marginTop: PropTypes.string,
    fontWeight: PropTypes.string,
    color: PropTypes.string,
};
