import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
    /*Standar Start */
    displayFlex: {
        display: 'flex',
    },
    /*Standar End   */
    /* SignIn Start */
    paper_SignIn: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form_SignIn: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit_SignIn: {
        margin: theme.spacing(3, 0, 2),
    },
    /* SignIn End   */

    /* MainLayout Start */
    content_MainLayout: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    appBarSpacer_MainLayout: theme.mixins.toolbar,
    container_MainLayout: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },

    /* Sidebar Start */
    toolbarIcon_Sidebar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    drawerPaper_Sidebar: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose_Sidebar: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    /* Sidebar End   */
    /* MainLayout End   */

    /* SignIn Start */
    /* SignIn End   */
}));
