import React, { createRef } from 'react';
import { useStyles } from '../../styles';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
    Grid,
    List,
    Box,
    ListItemText,
    ListItem,
    Typography,
    Button,
    withStyles,
} from '@material-ui/core';
import { DetailAppointment } from './components';

const StyledListItem = withStyles({
    root: {
        '&.Mui-selected': {
            backgroundColor: '#01A19C',
        },
    },
})(ListItem);

const View = (props) => {
    const {
        listAppointment,
        listEvets,
        selectedId,
        handleSelectId,
        handleOpenDetail,
        openDetail,
        vehicle,
        onChangeListAppointment,
    } = props;

    const classes = useStyles();
    const localizer = momentLocalizer(moment);

    const refs = listAppointment.reduce((acc, value) => {
        acc[value._id] = createRef();
        return acc;
    }, {});

    const handleScrollTo = (id) => {
        refs[id].current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const EventComponent = ({ event }) => {
        return (
            <div onClick={() => handleSelectId(event.id)}>
                <em>{event.title}</em>
                <p>{`${event.resource.userName} - ${event.resource.phone_number} - ${
                    event.resource.address
                } - Rent vehicle: ${event.resource.rent_vehicle ? 'Yes' : 'No'}`}</p>
                <Button
                    size='small'
                    variant='contained'
                    color='primary'
                    onClick={() => handleOpenDetail(true, event)}
                >
                    Detail
                </Button>
            </div>
        );
    };
    const EventComponentDay = ({ event }) => {
        return (
            <span>
                <p>{`${event.title} - ${event.resource.userName} - ${
                    event.resource.address
                } - Rent vehicle: ${event.resource.rent_vehicle ? 'Yes' : 'No'}`}</p>
            </span>
        );
    };
    const EventComponentWeek = ({ event }) => {
        return (
            <span>
                <p style={{ fontSize: 11 }}>{event.title} </p>
                <p style={{ fontSize: 11 }}>{event.resource.userName} </p>
            </span>
        );
    };

    return (
        <div>
            {selectedId.id !== '' ? (
                <DetailAppointment
                    idAppointment={selectedId.id}
                    open={openDetail}
                    handleClose={handleOpenDetail}
                    vehicle={vehicle}
                />
            ) : null}
            <Grid container>
                <Grid item lg={2} md={2} xl={3} xs={3}>
                    <Box style={{ overflow: 'auto', height: '80vh' }}>
                        <List component='nav'>
                            {listAppointment.map((appointment, index) => {
                                return (
                                    <StyledListItem
                                        selected={appointment._id === selectedId.id}
                                        onClick={() => {
                                            handleSelectId(appointment._id);
                                        }}
                                        onDoubleClick={() =>
                                            handleOpenDetail(true, appointment.vehicle_id)
                                        }
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            borderBottom: '1px solid #c6bdbd',
                                            flexDirection: 'column',
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                        }}
                                        ref={refs[appointment._id]}
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                paddingLeft: 15,
                                                paddingRight: 15,
                                            }}
                                        >
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <Typography
                                                        type='body2'
                                                        style={{ fontSize: 13 }}
                                                    >
                                                        {appointment.pickup_date}
                                                    </Typography>
                                                }
                                            />
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-around',
                                                }}
                                            >
                                                <ListItemText
                                                    disableTypography
                                                    primary={
                                                        <Typography
                                                            type='body2'
                                                            style={{
                                                                fontSize: 13,
                                                                fontWeight: 800,
                                                            }}
                                                        >
                                                            Pickup
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <Typography
                                                            type='caption'
                                                            style={{ fontSize: 10 }}
                                                        >
                                                            {appointment.pickup_time}
                                                        </Typography>
                                                    }
                                                />
                                            </div>

                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <Typography
                                                        type='body2'
                                                        style={{ fontSize: 13, fontWeight: 800 }}
                                                    >
                                                        Address:
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography
                                                        type='caption'
                                                        style={{ fontSize: 10 }}
                                                    >{`${appointment.pickup_address.street}, ${appointment.pickup_address.street_2}, ${appointment.pickup_address.state}, ${appointment.pickup_address.city}, ${appointment.pickup_address.zip}`}</Typography>
                                                }
                                            />
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <Typography
                                                        type='body2'
                                                        style={{ fontSize: 13, fontWeight: 800 }}
                                                    >
                                                        Name:
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography
                                                        type='caption'
                                                        style={{ fontSize: 10 }}
                                                    >
                                                        {appointment.userName}
                                                    </Typography>
                                                }
                                            />
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <Typography
                                                        type='body2'
                                                        style={{ fontSize: 13, fontWeight: 800 }}
                                                    >
                                                        Phone Number
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography
                                                        type='caption'
                                                        style={{ fontSize: 10 }}
                                                    >
                                                        {appointment.phone_number}
                                                    </Typography>
                                                }
                                            />
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <Typography
                                                        type='body2'
                                                        style={{ fontSize: 13, fontWeight: 800 }}
                                                    >
                                                        Rent Vehicle
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography
                                                        type='caption'
                                                        style={{ fontSize: 10 }}
                                                    >
                                                        {appointment.rent_vehicle ? 'Yes' : 'No'}
                                                    </Typography>
                                                }
                                            />
                                        </div>
                                    </StyledListItem>
                                );
                            })}
                        </List>
                    </Box>
                </Grid>
                <Grid item lg={10} md={10} xl={9} xs={9}>
                    <Calendar
                        step={60}
                        popup
                        localizer={localizer}
                        events={listEvets}
                        defaultDate={moment().toDate()}
                        // timeslots={1}
                        style={{ height: 500 }}
                        resizable
                        onSelectEvent={(selectId) => {
                            handleScrollTo(selectId.id);
                            handleSelectId(selectId);
                        }}
                        selected={listEvets[selectedId.index]}
                        onDoubleClickEvent={(res) => handleOpenDetail(true, res)}
                        onView={(e) => console.log('onView', e)}
                        onRangeChange={onChangeListAppointment}
                        components={{
                            week: {
                                event: EventComponentWeek,
                            },
                            day: {
                                event: EventComponentDay,
                            },
                            agenda: {
                                event: EventComponent,
                            },
                        }}
                        eventPropGetter={(event, start, end, isSelected) => {
                            var style = {
                                borderRadius: '5px',
                                backgroundColor: isSelected ? '#01A19C' : '#F4F6F8',
                                opacity: 0.8,
                                color: 'black',
                                borderColor: '#01A19C',
                                borderStyle: 'solid',
                                borderWidth: '2px',
                            };
                            return {
                                style: style,
                            };
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

View.propTypes = {
    listAppointment: PropTypes.array.isRequired,
    listEvets: PropTypes.array.isRequired,
    selectedId: PropTypes.object.isRequired,
    handleSelectId: PropTypes.func.isRequired,
    handleOpenDetail: PropTypes.func.isRequired,
    openDetail: PropTypes.bool.isRequired,
    vehicle: PropTypes.object.isRequired,
    onChangeListAppointment: PropTypes.func.isRequired,
};
export default View;
