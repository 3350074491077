import { LOGIN_START, LOGOUT_START, FORGOT_PASSWORD_USER, RESET_PASSWORD } from '../actionType';

export const loginAction = (userData) => ({
    type: LOGIN_START,
    userData,
});

export const logoutAction = () => ({
    type: LOGOUT_START,
});

export const forgotPasswordAction = (email) => ({
    type: FORGOT_PASSWORD_USER,
    email,
});

export const resetPasswordAction = (data, method, reqForNextDriver) => ({
    type: RESET_PASSWORD,
    data,
    method,
    reqForNextDriver,
});
