import { get } from 'lodash';

import {
    APPOINTMENT_COMPLETE,
    FETCH_USER_COMPLETE,
    GET_APPOINTMENT_BY_USER_COMPLETE,
} from '../actionType';

const initialState = {
    appointmentList: [],
    scheduleList: [],
    usersList: [],
    appointmentByUser: null,
};

export const appointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_COMPLETE:
            return {
                ...state,
                usersList: get(action, 'usersList'),
            };
        case APPOINTMENT_COMPLETE:
            const listUser = state.usersList;
            const responseListAppointments = get(action, 'appointmentList');
            let tempListScheduleEvents = [];
            let tempListAppointments = [];
            for (const appointment of responseListAppointments) {
                let userData = listUser.find((user) => {
                    return appointment.user_id === user._id;
                });
                if (typeof userData === 'undefined') {
                    continue;
                }
                let date = appointment.pickup_date.split('-');
                let timePickUpTime = appointment.pickup_time.split(':');
                tempListAppointments = [
                    ...tempListAppointments,
                    {
                        ...appointment,
                        userName: userData.first_name + ' ' + userData.last_name,
                        phone_number: userData.phone_number,
                        pickupDate: new Date(
                            parseInt(date[2]),
                            parseInt(date[0] - 1),
                            parseInt(date[1]),
                            parseInt(timePickUpTime[0]),
                            parseInt(timePickUpTime[1]),
                            0
                        ),
                    },
                ];
                tempListScheduleEvents = [
                    ...tempListScheduleEvents,
                    {
                        id: appointment._id,
                        start: new Date(
                            parseInt(date[2]),
                            parseInt(date[0] - 1),
                            parseInt(date[1]),
                            parseInt(timePickUpTime[0]),
                            parseInt(timePickUpTime[1]),
                            0
                        ),
                        end: new Date(
                            parseInt(date[2]),
                            parseInt(date[0] - 1),
                            parseInt(date[1]),
                            parseInt(timePickUpTime[0]) + 1,
                            parseInt(timePickUpTime[1]),
                            0
                        ),
                        title: 'P/U ' + userData.first_name,
                        resource: {
                            id: appointment._id,
                            vehicle_id: appointment.vehicle_id,
                            duty: 'entregar',
                            color: '01A19C',
                            userName: userData.first_name + ' ' + userData.last_name,
                            phone_number: userData.phone_number,
                            address: `${appointment.pickup_address.street}, ${appointment.pickup_address.street_2}, ${appointment.pickup_address.state}, ${appointment.pickup_address.city}, ${appointment.pickup_address.zip}`,
                            rent_vehicle: appointment.hasOwnProperty('rent_vehicle')
                                ? appointment.rent_vehicle
                                : false,
                        },
                    },
                ];
            }
            let orderTempListAppointments = [...tempListAppointments].sort(
                (a, b) => a.pickupDate - b.pickupDate
            );
            return {
                ...state,
                appointmentList: orderTempListAppointments,
                scheduleList: tempListScheduleEvents,
            };

        case GET_APPOINTMENT_BY_USER_COMPLETE:
            return {
                ...state,
                appointmentByUser: get(action, 'appointmentByUser'),
            };

        default:
            return state;
    }
};
