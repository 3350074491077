import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { tokenSelector } from '../../redux/selector';

const Index = (props) => {
    const token = useSelector((state) => tokenSelector(state));

    const { layout: Layout, component: Component, ...rest } = props;

    return (
        <Route
            {...rest}
            render={({ location }) =>
                token ? (
                    <Layout>
                        <Component />
                    </Layout>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

Index.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string,
};

export default Index;
