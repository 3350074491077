import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import View from './view';

import {
    getAppointmentByUserSelector,
    getUserVehicleSelector,
    usersListSelector,
    licenseIDSelector,
    isLoadingUserDataSelector,
} from '../../redux/selector';
import { getUserVehicleListAction } from '../../redux/actions/vehicleAction';
import { getAppointmentByUserAction } from '../../redux/actions/appointmentAction';
import { userAction } from '../../redux/actions/userAction';
import { licenseIDAction } from '../../redux/actions/licenseIDAction';
import { notificationAction } from '../../redux/actions/notificationAction';

const Index = () => {
    const dispatch = useDispatch();
    const getUserVehicleList = useSelector((state) => getUserVehicleSelector(state));
    const getAppointmentByUser = useSelector((state) => getAppointmentByUserSelector(state));
    const usersList = useSelector((state) => usersListSelector(state));
    const licenseID = useSelector((state) => licenseIDSelector(state));
    const isLoadingUserData = useSelector((state) => isLoadingUserDataSelector(state));

    const [openModalLicense, setOpenModalLicense] = useState({ open: false, img: '' });
    const [listUser, setListUser] = useState(usersList);
    const [search, setSearch] = useState('');

    const [notification, setNotification] = useState({
        message: '',
        email: '',
    });

    const initialState = {
        _admin: false,
        _id: '',
        email: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        listVehicle: [],
        listappointment: [],
        driver_license_img: null,
        insurance_id_img: null,
        idVehicle: '',
        appointmentId: '',
    };

    const [user, setUser] = useState({
        ...initialState,
    });

    useEffect(() => {
        !usersList.length && dispatch(userAction());
        setListUser(usersList);

        setUser((userOld) => ({
            ...userOld,
            listVehicle: getUserVehicleList || [],
            listappointment: getAppointmentByUser || [],
            ...licenseID,
        }));
    }, [usersList, getUserVehicleList, getAppointmentByUser, licenseID]);

    const handelSelectUser = async (userData) => {
        setUser({ ...initialState, ...userData });
        setNotification({ ...notification, email: userData.email });
        getUserData(userData._id);
    };

    const getUserData = async (userID) => {
        dispatch(getUserVehicleListAction(userID));
        dispatch(getAppointmentByUserAction(userID));
        dispatch(licenseIDAction(userID));
    };

    const handleNotification = (e) => {
        setNotification({
            ...notification,
            message: e.target.value,
        });
    };

    const handleSendNotification = async () => {
        dispatch(notificationAction(notification));
        setTimeout(() => {
            setNotification((prev) => ({
                ...prev,
                message: '',
            }));
        }, 2000);
    };

    const handleSearch = (e) => {
        let searchValue = e.target.value;
        setSearch(searchValue);
        const searchList = usersList.filter((user) => filterUserList(user, searchValue));
        setListUser(searchList);
    };

    const filterUserList = (obj, value) => {
        if (obj.email || obj.first_name || obj.last_name) {
            let minUser = (obj.email + obj.first_name + ' ' + obj.last_name).toLowerCase();
            return minUser.includes(value);
        } else {
            return false;
        }
    };

    const handleSelectVehicle = (id, appointment) => {
        setUser({ ...user, idVehicle: id, appointmentId: appointment });
    };

    return (
        <View
            listUser={listUser}
            handelSelectUser={handelSelectUser}
            isLoading={isLoadingUserData}
            userSeleted={user}
            openModalLicense={openModalLicense}
            handleNotification={handleNotification}
            notification={notification}
            handleSendNotification={handleSendNotification}
            setOpenModalLicense={setOpenModalLicense}
            handleSearch={handleSearch}
            search={search}
            handleSelectVehicle={handleSelectVehicle}
        />
    );
};

export default Index;
