import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';

import { SnackbarAlert, BackdropProgress } from './components';
import { ScheduleDataProvider } from './views/Appointment/context';
import Routes from './Routes';
import theme from './theme';
import './App.css';

function App() {
    return (
        <ScheduleDataProvider>
            <ThemeProvider theme={theme}>
                <Router>
                    <BackdropProgress />
                    <Routes />
                    <SnackbarAlert />
                </Router>
            </ThemeProvider>
        </ScheduleDataProvider>
    );
}

export default App;
