import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Button } from '@material-ui/core';

import { AppDownloads } from '../../../components';
import styles from '../../../assets/styles/headerLinksStyle';

import { useHistory } from 'react-router-dom';
const useStyles = makeStyles(styles);

export default function HeaderLinks() {
    const classes = useStyles();
    let history = useHistory();
    return (
        <List className={classes.list}>
            <ListItem className={classes.listItem}>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        history.push('/sign-in');
                    }}
                >
                    login
                </Button>
            </ListItem>
            <AppDownloads />
        </List>
    );
}
