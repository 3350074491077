import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import View from './view';

import { appointmentAction } from '../../redux/actions/appointmentAction';
import { fetchVehicleByIdAction } from '../../redux/actions/vehicleAction';
import { FETCH_VEHICLE_BY_ID_COMPLETE } from '../../redux/actionType';
import {
    appointmentSelector,
    scheduleListSelector,
    singleVehicleSelector,
} from '../../redux/selector';

const timeNow = new Date();

const Index = () => {
    const dispatch = useDispatch();
    const appointmentList = useSelector((state) => appointmentSelector(state));
    const scheduleList = useSelector((state) => scheduleListSelector(state));
    const singleVehicle = useSelector((state) => singleVehicleSelector(state));

    const [openDetail, setOpenDetail] = useState(false);
    const [rangeDate, setRangeDate] = useState();

    const [listAppointment, setListAppointment] = useState([
        ...appointmentList.filter(
            (appointment) => appointment.pickupDate.getMonth() === timeNow.getMonth()
        ),
    ]);
    const [idAppointment, setIdAppointment] = useState({
        id: '',
        index: -1,
    });

    useEffect(() => {
        if (!appointmentList.length) dispatch(appointmentAction());
        if (rangeDate) {
            onChangeListAppointment(rangeDate);
        } else {
            setListAppointment([
                ...appointmentList.filter(
                    (appointment) => appointment.pickupDate.getMonth() === timeNow.getMonth()
                ),
            ]);
        }
    }, [appointmentList]);

    const handleOpenDetail = async (open, id) => {
        if (id) {
            if (typeof id === 'string') {
                dispatch(fetchVehicleByIdAction(id));
            } else {
                dispatch(fetchVehicleByIdAction(id.resource.vehicle_id));
            }
        }

        if (!open) {
            dispatch({ type: FETCH_VEHICLE_BY_ID_COMPLETE, singleVehicle: null });
        }
        setOpenDetail(open);
    };

    const handleSelectAppointment = (selectId) => {
        if (typeof selectId === 'string') {
            setIdAppointment({
                id: selectId,
                index: scheduleList.findIndex((obj) => {
                    return obj.resource.id == selectId;
                }),
            });
        } else {
            setIdAppointment({
                index: scheduleList.findIndex((obj) => {
                    return (
                        obj.resource.id == selectId.resource.id &&
                        obj.resource.duty == selectId.resource.duty
                    );
                }),
                id: selectId.resource.id,
            });
        }
    };

    const onChangeListAppointment = (range) => {
        setRangeDate(range);
        const immutableSort = (arr, compareFunction) => {
            return [...arr].sort(compareFunction);
        };
        const compare = (a, b) => {
            if (a.pickupDate.getTime() < b.pickupDate.getTime()) {
                return -1;
            }
            if (a.pickupDate.getTime() > b.pickupDate.getTime()) {
                return 1;
            }
            return 0;
        };
        let tempListAppointment = [];
        if (Array.isArray(range)) {
            if (range.length === 1) {
                tempListAppointment = [
                    ...appointmentList.filter(
                        (appointment) =>
                            appointment.pickupDate.getDate() === range[0].getDate() &&
                            appointment.pickupDate.getMonth() === range[0].getMonth() &&
                            appointment.pickupDate.getFullYear() === range[0].getFullYear()
                    ),
                ];
                setListAppointment(immutableSort(tempListAppointment, compare));
            } else {
                tempListAppointment = [
                    ...appointmentList.filter(
                        (appointment) =>
                            appointment.pickupDate.getTime() >= range[0].getTime() &&
                            appointment.pickupDate.getTime() <= range[6].getTime()
                    ),
                ];
                setListAppointment(immutableSort(tempListAppointment, compare));
            }
        } else {
            tempListAppointment = [
                ...appointmentList.filter(
                    (appointment) =>
                        appointment.pickupDate.getTime() >= range.start.getTime() &&
                        appointment.pickupDate.getTime() <= range.end.getTime()
                ),
            ];
            setListAppointment(immutableSort(tempListAppointment, compare));
        }
    };

    return (
        <View
            listAppointment={listAppointment}
            listEvets={scheduleList}
            selectedId={idAppointment}
            handleSelectId={handleSelectAppointment}
            openDetail={openDetail}
            handleOpenDetail={handleOpenDetail}
            vehicle={singleVehicle}
            onChangeListAppointment={onChangeListAppointment}
        />
    );
};

export default Index;
