import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { snackbarAction } from '../../redux/actions/utilityAction';
import { snackbarSelector } from '../../redux/selector';

export default () => {
    const dispatch = useDispatch();
    const snackbar = useSelector((state) => snackbarSelector(state));

    const closeSnackbarAlert = () => {
        dispatch(
            snackbarAction({
                open: false,
                type: '',
                message: '',
            })
        );
    };

    return (
        <Snackbar
            autoHideDuration={3000}
            open={snackbar.open}
            onClose={closeSnackbarAlert}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <MuiAlert
                elevation={6}
                variant='filled'
                onClose={closeSnackbarAlert}
                severity={snackbar.type}
            >
                {snackbar.message}
            </MuiAlert>
        </Snackbar>
    );
};
