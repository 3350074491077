const isBrowser = window !== 'undefined';

export const getLocalStorage = (name = TOKEN) => isBrowser && localStorage.getItem(name);

export const setLocalStorage = (value, name = TOKEN) =>
    isBrowser && localStorage.setItem(name, value);

export const rmLocalStorage = (name = TOKEN) => isBrowser && localStorage.removeItem(name);

export const TOKEN = 'X-TOKEN-NEXT-SERVICE';
export const REFRESH_TOKEN = 'X-R-TOKEN-NEXT-SERVICE';
