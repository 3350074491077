import { get } from 'lodash';

import { FETCH_USER_COMPLETE, IS_LOADING_USER } from '../actionType';

const initialState = {
    isLoadingUserData: false,
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case IS_LOADING_USER:
            return {
                ...state,
                isLoadingUserData: get(action, 'isLoadingUserData'),
            };
        default:
            return state;
    }
};
