import React, { useContext, useState } from 'react';
// import { useNavigate  } from 'react-router';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import { BASE_URL, SCHEDULE_SERVICE_FOR_IFRAME } from '../../utility/urls';
import { ScheduleData, AGREEMENT } from './context';
import { View } from './view';
import axios from 'axios';



export const Appointment = () => {
    NProgress.start();
    const { state, dispatch } = useContext(ScheduleData);
    const { control, handleSubmit, reset } = useForm();
    const [alert, setAlert] = useState(false);
    const [payNow, setPayNow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const inputFields = {
        year: {
            name: 'Year',
        },
        make: {
            name: 'Make',
        },
        model: {
            name: 'Model',
        },
        vin: {
            name: 'VIN',
        },
    };

    const inputFieldsForCustomerData = {
        first: {
            name: 'First Name',
        },
        last: {
            name: 'Last Name',
        },
        email: {
            name: 'Email Address',
        },
        phone: {
            name: 'Mobile Phone',
        },
    };

    const payNowSuccessfully = async () => {
        const clientSecret = await axios.post(BASE_URL + '/checkout', {
                item: state.rentalStatus === 'true' ? 'rental': 'norental'
            }
        )

        if(clientSecret.data?.error){
            setAlert({ type: 'error', message: 'Internal Error!' });
            return;
        }

        if(!payNow) {
            setPayNow(clientSecret.data.id)
            return;
        }
    }

    const payNowCloseHandler = () => {
        setPayNow(false)
    }

    console.log('[PayNow]',payNow)


    const submitFormHandler = async (allData) => {

        const { make, year, model, first, last, email, phone, vin } = allData;

        if (
            !year ||
            !make ||
            !model ||
            !first ||
            !last ||
            !email ||
            !phone ||
            !state.address.city ||
            !state.address.state ||
            !state.address.zip
        ) {
            setAlert({ type: 'error', message: 'All fields are required!' });
            return;
        }

        if (!state.agreement) {
            setAlert({ type: 'error', message: 'You should accept our agreement' });
            return;
        }
        setAlert(false);
        setIsLoading(true);

        const data = {
            email,
            first_name: first,
            last_name: last,
            phone,
            rental: state.rentalStatus === 'true' || false,
            make,
            model,
            year,
            vin,
            pickup_address: state.address,
            pickup_date: dayjs(state.pickup_date).format('MM-DD-YYYY'),
            pickup_time: state.pickup_time,
        };

        
        if(!payNow) {
            payNowSuccessfully()
            return;
        }

        const result = await axios.post(BASE_URL + SCHEDULE_SERVICE_FOR_IFRAME, data);

        if (result.status) {
            setAlert({
                type: 'success',
                message: 'You appointment successfully created!',
            });
            reset();
            dispatch({ type: AGREEMENT, payload: false });
        }
        setIsLoading(false);
    };

    return (
        <MuiPickersUtilsProvider utils={DayJsUtils}>
            <View
                inputFields={inputFields}
                inputFieldsForCustomerData={inputFieldsForCustomerData}
                alert={alert}
                control={control}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
                submitFormHandler={submitFormHandler}
                payNow={payNow}
                payNowCloseHandler={payNowCloseHandler}
            />

        </MuiPickersUtilsProvider>
    );
};
