import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isLoadingAppSelector } from '../../redux/selector';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        background: 'rgba(255,255,255,0.5)',
    },
}));

export default () => {
    const isLoadingApp = useSelector((state) => isLoadingAppSelector(state));
    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={isLoadingApp}>
            <CircularProgress size={75} />
        </Backdrop>
    );
};
