import { put, call, takeLatest, select } from 'redux-saga/effects';

import { NOTIFICATION_SEND_TO_USER } from '../actionType';
import { NOTIFICATIONS_ONE_USER } from '../../utility/urls';
import { snackbarAction } from '../actions/utilityAction';
import { apiCall } from '../http/apiCall';
import { tokenSelector } from '../selector';
import { throwError } from '../../utility/error';

function* sendNotificationToUser({ message }) {
    try {
        const token = yield select(tokenSelector);
        yield call(apiCall, NOTIFICATIONS_ONE_USER, message, token, 'POST');

        yield put(
            snackbarAction({
                open: true,
                type: 'success',
                message: 'Notification Sent.',
            })
        );
    } catch (error) {
        yield put(throwError(error));
    }
}

export default function* notificationWatch() {
    yield takeLatest(NOTIFICATION_SEND_TO_USER, sendNotificationToUser);
}
