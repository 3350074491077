import React from 'react';
import { Grid, makeStyles, Box } from '@material-ui/core/';
import PropTypes from 'prop-types';

import { Title, Paragraph, Input } from '../';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '40px',
    },
}));

export const CustomerInformation = ({ inputFieldsForCustomerData, control }) => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Title title='Customer Information' font='secondary' variant='h5' bold='900' />
            <Paragraph>
                We need your contact and vehicle information so we can pick up your vehicle for
                service. We will send you an email confirmation and SMS updates when our driver is
                on the way.
            </Paragraph>

            <Grid container spacing={2}>
                {Object.keys(inputFieldsForCustomerData).map((name) => (
                    <Grid item md={6} xs={12} key={name + Math.random() * 999}>
                        <Input
                            name={name}
                            label={inputFieldsForCustomerData[name].name}
                            control={control}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

CustomerInformation.propTypes = {
    inputFieldsForCustomerData: PropTypes.object.isRequired,
};
