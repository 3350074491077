import { GET_USER_VEHICLE_LIST_START, FETCH_VEHICLE_BY_ID_START } from '../actionType';

export const getUserVehicleListAction = (id) => ({
    type: GET_USER_VEHICLE_LIST_START,
    id,
});

export const fetchVehicleByIdAction = (vehicleId) => ({
    type: FETCH_VEHICLE_BY_ID_START,
    vehicleId,
});
