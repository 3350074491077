import React, { useContext } from 'react';
import { Grid, makeStyles, Box, Typography } from '@material-ui/core/';
import Alert from '@material-ui/lab/Alert';
import { DatePicker } from '@material-ui/pickers';

import { ScheduleData, ADDRESS, DATE, TIME, IS_LOCATION_INSIDE_RADIUS } from '../../context';
import { Title, Paragraph, Select, Map, Autocomplete } from '../';
import dayjs from 'dayjs';

/**
 * Config
 */

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '40px',
    },
    inputText: {
        width: '100%',
    },
    autocomplete: {
        height: '40px',
        paddingLeft: '16px',
        marginTop: '2px',
        boxSizing: 'border-box',
    },
    grid: {
        marginTop: theme.spacing(5),
    },
    alert: {
        marginTop: theme.spacing(5),
    },
    color: {
        color: '#616161',
    },
    space: {
        marginTop: theme.spacing(3),
    },
    picker: {
        '& > .MuiInputBase-root': {
            height: 39,
        },
    },
    notice: {
        marginTop: 18,
        fontSize: 12,
    },
}));

/**
 * Creating hours like: 08:00, 9:15.... to 4:45
 */
const addZeroInfont = (num) => {
    if (num < 10) return '0' + num;
    return num;
};

const openHours = [];

for (let i = 0; i <= 8; ++i) {
    for (let j = 0; j <= 3; ++j) {
        openHours.push(`${addZeroInfont(8 + i)}:${j * 15 === 0 ? '00' : j * 15}`);
    }
}

export const LocationAndTime = () => {
    const { state, dispatch } = useContext(ScheduleData);
    const classes = useStyles();

    const selectDateHandler = (date) => {
        dispatch({ type: DATE, payload: date });
    };

    const selectTimeHandler = (event) => {
        dispatch({ type: TIME, payload: event.target.value });
    };

    const onPlaceSelectedHandler = (place) => {
        const newAddress = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
        };

        // create LatLng object for the emputeDistanceBetween
        const center = new window.google.maps.LatLng(25.4844904, -80.4616389);

        const to = new window.google.maps.LatLng(
            place.geometry.location.lat(),
            place.geometry.location.lng()
        );

        const contains =
            window.google.maps.geometry.spherical.computeDistanceBetween(center, to) <= 80467.2;

        // check if we don't offer any service to selected location
        if (!contains) dispatch({ type: IS_LOCATION_INSIDE_RADIUS, payload: true });

        if (contains) dispatch({ type: IS_LOCATION_INSIDE_RADIUS, payload: false });

        if (contains)
            for (let item of place?.address_components) {
                if (item.types.includes('street_number'))
                    newAddress['street'] = item.long_name + ' ';
                if (item.types.includes('route')) newAddress['street'] += item.long_name;
                if (item.types.includes('administrative_area_level_1'))
                    newAddress['state'] = item.long_name;
                if (item.types.includes('locality')) newAddress['city'] = item.long_name;
                if (item.types.includes('postal_code')) newAddress['zip'] = item.long_name;
            }
        newAddress['street2'] = '';

        dispatch({ type: ADDRESS, address: newAddress });
    };

    const disableWeekends = (date) => {
        return date.day() === 0 || date.date() === 24 || date.date() === 25;
    }

    return (
        <Box className={classes.container}>
            <Title title='Select a time and location' font='secondary' variant='h5' bold='900' />
            <Paragraph>
                Tell us when and where we can pick up your vehicle, and any instructions needed to
                access your location or how to find you.
            </Paragraph>

            <Grid container spacing={3} classes={{ root: classes.grid }}>
                <Grid item md={6}>
                    <Box>
                        <Typography className={classes.color}>
                            Where can we pick up your vehicle?
                        </Typography>

                        {/* For Auto complete Search Box */}
                        <Autocomplete onPlaceSelected={onPlaceSelectedHandler} />
                    </Box>

                    <Box className={classes.space}>
                        <Typography className={classes.color}>
                            What time can we pick up your vehicle?
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <DatePicker
                                    minDate={dayjs().add(3, 'day').$d}
                                    disablePast
                                    value={state.pickup_date}
                                    onChange={selectDateHandler}
                                    animateYearScrolling
                                    className={classes.picker}
                                    inputVariant='outlined'
                                    shouldDisableDate={disableWeekends}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    value={state.pickup_time}
                                    handleChange={selectTimeHandler}
                                    options={openHours}
                                    inputText={state.pickup_time}
                                />
                            </Grid>
                        </Grid>
                        <Typography className={[classes.color, classes.notice]}>
                            Please be advised: pick up/drop off services must be scheduled 72 hours
                            (3 days) in advanced to secure both your maintenance appointment and
                            pick up/drop off services.
                        </Typography>
                    </Box>

                    {state.isLocationInsideRadius && (
                        <Alert className={classes.alert} severity='error'>
                            Sorry, At the moment we don't offer any service to selected address.
                        </Alert>
                    )}
                </Grid>

                <Grid item md={6} xs={12}>
                    <Map
                        lat={state.address.lat}
                        lng={state.address.lng}
                        isMarkerShown
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `400px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
