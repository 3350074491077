import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Header, Parallax, PrivacyPolicy, HeaderLinks } from './components';
import styles from '../../assets/styles/homePage';

const dashboardRoutes = [];
const useStyles = makeStyles(styles);
const View = (props) => {
    const classes = useStyles();
    return (
        <div>
            <Header
                color='transparent'
                routes={dashboardRoutes}
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: 'white',
                }}
            />
            <Parallax filter image={require('../../assets/img/backgroudHome.png')}></Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <PrivacyPolicy />
                </div>
            </div>
        </div>
    );
};

export default View;
