import React, { createContext, useReducer } from 'react';
import dayjs from 'dayjs';

export const ScheduleData = createContext();

const initialState = {
    data: { foo: 'xx' },
    rentalStatus: 'true',
    isLocationInsideRadius: false,
    address: {
        full: 'Homestead, FL, USA',
        street: 'r',
        city: '',
        state: '',
        zip: '',
        lat: 25.4844904,
        lng: -80.4616389,
    },
    pickup_date: dayjs().add(3, 'day').$d,
    pickup_time: '09:00',
    agreement: false,
};

export const reducer = (state, action) => {
    switch (action.type) {
        case ADDRESS:
            return {
                ...state,
                address: action.address,
            };

        case RENTAL:
            return {
                ...state,
                rentalStatus: action.payload,
            };
        case AGREEMENT:
            return {
                ...state,
                agreement: action.payload,
            };
        case DATE:
            return {
                ...state,
                pickup_date: action.payload,
            };
        case TIME:
            return {
                ...state,
                pickup_time: action.payload,
            };

        case IS_LOCATION_INSIDE_RADIUS:
            return {
                ...state,
                isLocationInsideRadius: action.payload,
            };
        default:
            return state;
    }
};

export const ScheduleDataProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    // Learn more in http://kcd.im/optimize-context
    const value = { state, dispatch };
    return <ScheduleData.Provider value={value}>{children}</ScheduleData.Provider>;
};

export const ADDRESS = 'ADDRESS';
export const RENTAL = 'RENTAL';
export const AGREEMENT = 'AGREEMENT';
export const DATE = 'DATE';
export const TIME = 'TIME';
export const IS_LOCATION_INSIDE_RADIUS = 'IS_LOCATION_INSIDE_RADIUS';
