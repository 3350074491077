import React from 'react';
import { useStyles } from '../../styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
    Button,
    TextField,
    CssBaseline,
    Grid,
    Container,
    InputAdornment,
    IconButton,
    Link as MLink,
} from '@material-ui/core';
import logo from '../../assets/img/logoNextLogin.png';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const View = (props) => {
    const {
        loginState,
        onChangeLogin,
        handleOnSummit,
        onEnter,
        showPassword,
        handleClickShowPassword,
        handleMouseDownPassword,
    } = props;
    const classes = useStyles();
    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <div className={classes.paper_SignIn}>
                <img src={logo} alt='Logo' />
                <div className={classes.form_SignIn} noValidate onKeyDown={onEnter}>
                    <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        id='email'
                        label='Username'
                        name='username'
                        autoComplete='email'
                        value={loginState.username}
                        onChange={onChangeLogin}
                        autoFocus
                    />
                    <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        name='password'
                        label='Password'
                        id='password'
                        value={loginState.password}
                        onChange={onChangeLogin}
                        autoComplete='current-password'
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position={'end'}>
                                    <IconButton
                                        aria-label={'toggle password visibility'}
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge={'end'}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        className={classes.submit_SignIn}
                        onClick={handleOnSummit}
                    >
                        Sign In
                    </Button>
                    <Grid item xs>
                        <MLink component={Link} to='/forgot-password' variant='body2'>
                            Forgot password?
                        </MLink>
                    </Grid>
                </div>
            </div>
        </Container>
    );
};

View.propTypes = {
    loginState: PropTypes.object.isRequired,
    onChangeLogin: PropTypes.func.isRequired,
    handleOnSummit: PropTypes.func.isRequired,
    onEnter: PropTypes.func.isRequired,
    showPassword: PropTypes.bool.isRequired,
    handleClickShowPassword: PropTypes.func.isRequired,
    handleMouseDownPassword: PropTypes.func.isRequired,
};
export default View;
