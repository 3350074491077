import {Button, Snackbar} from '@material-ui/core'
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { PaymentElement } from '@stripe/react-stripe-js';
import {useStripe, useElements } from '@stripe/react-stripe-js';

const CheckoutForm = ({submitFormHandler, paymentDone, handleSubmit}) => {
    const stripe = useStripe();
    const elements = useElements();
      const [open, setOpen] = React.useState(false);




    const onCheckoutHandler = async ()=> {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const payResult = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            redirect: 'if_required'
        });

        if (payResult.error) {
        // Show error to your customer (for example, payment details incomplete)
            setOpen(payResult.error.message)
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            paymentDone(payResult.paymentIntent.id)
            handleSubmit(submitFormHandler)()
        }
    }


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
    };

    return (
        <>
            <PaymentElement />
            <Button onClick={onCheckoutHandler} variant="outlined" fullWidth style={{marginTop: 26}}>Pay Now</Button>

            <Snackbar
                severity="warning"
                elevation={6} 
                variant="filled"
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={open}
                action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            />
        </>

    );
};

export default CheckoutForm;