import React, {useContext, useState} from 'react';
import { makeStyles, Box, Divider, List, Typography, Modal, Button } from '@material-ui/core/';
import PropTypes from 'prop-types';
import { ScheduleData } from './context';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import Checkout from './components/checkout'
import { AppDownloads } from '../../components/';
import Logo from '../../assets/img/logoNextLogin.png';
import {
    Title,
    Paragraph,
    RentalChoice,
    VehicleDetails,
    LocationAndTime,
    CustomerInformation,
    BookReservation,
} from './components/';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 1200,
        margin: '20px auto',
    },
    formWrapper: {
        maxWidth: 1000,
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
    },
    space: {
        margin: '20px 0',
    },
    list: {
        display: 'flex',
    },
}));


const stripePromise = loadStripe('pk_live_51HSR9aAQX38Hy7Nq5BZRo9xT9LP2dfALdcEnoFX7CQfMX8SItoi4jXIBMtlkyArGIXB5gOzqkVGjuCX3sm0h9ZU7003Poye6my');


export const View = ({
    inputFields,
    inputFieldsForCustomerData,
    alert,
    control,
    handleSubmit,
    submitFormHandler,
    payNow,
    payNowCloseHandler,
    isLoading,
    payNowSuccessfully
}) => {
    const classes = useStyles();
    const { state } = useContext(ScheduleData);
    const [transcationId, setTranscationId] = useState(false)

    const options = {
        // passing the client secret obtained from the server
        clientSecret: payNow,
    };

    const paymentDone = (id) => {
        setTranscationId(id)
    }

    return (
        <Box className={classes.root}>
            {/* <Title title="Schedule Service" font="primary" variant="h4" bold="900" /> */}
            <List className={classes.list}>
                <AppDownloads logo={Logo} />
            </List>
            <br />
            <Box className={classes.formWrapper}>
                <Title title="Let's book your service appointment" font='secondary' variant='h5' />
                <Paragraph>
                    Thank you for choosing us for your vehicle service needs. We can pick up your
                    vehicle from a location and time of your choosing. While we have your vehicle,
                    let us know if you need a rental vehicle. Please choose your preference.
                </Paragraph>

                <Paragraph fontWeight='bold' marginTop='15px' color='#FF0000'>
                    *Thank you for choosing our services for your vehicle needs. We are pleased to
                    offer a convenient pick-up service for your vehicle at a location that best
                    suits your schedule. Please note that additional charges may apply for this
                    service.
                </Paragraph>

                <Paragraph fontWeight='bold' marginTop='15px' color='#FF0000'>
                    During the time that your vehicle is with us, we would be happy to arrange for a
                    rental vehicle if needed. Please let us know your preference and we will do our
                    best to accommodate your request.
                </Paragraph>

                <RentalChoice />
                <Divider className={classes.space} />
                <VehicleDetails inputFields={inputFields} control={control} />
                <Divider className={classes.space} />
                <LocationAndTime />
                <Divider className={classes.space} />
                <CustomerInformation
                    inputFieldsForCustomerData={inputFieldsForCustomerData}
                    control={control}
                />
                <BookReservation
                    submitFormHandler={submitFormHandler}
                    handleSubmit={handleSubmit}
                    isLoading={isLoading}
                    alert={alert}
                />

                <Modal
                    style={{
                        margin: 'auto',
                        width: '80%',
                        maxWidth: 600,
                        marginTop: 50,
                    }}
                    open={payNow}
                    onClose={payNowCloseHandler}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {transcationId ? (
                        <div style={{
                                backgroundColor: 'white',
                                padding: 25,
                                textAlign: 'center'
                            }}>
                            <CheckCircleIcon fontSize={'large'} color='primary' />
                            <Typography style={{marginTop: 16, marginBottom: 36, textAlign: 'center'}} gutterBottom variant='h4'>
                                Congrats, You're registration completed successfully!
                            </Typography>
                            
                            <Typography style={{marginTop: 16, marginBottom: 36, textAlign: 'center'}} gutterBottom >
                                Transcation ID: #{transcationId}
                            </Typography>
                            
                            <Typography color='primary' style={{marginTop: 16, marginBottom: 36, textAlign: 'center'}} gutterBottom >
                                Note: please make a screenshot of this TRX ID, thanks
                            </Typography>

                            <Button onClick={payNowCloseHandler}>Done</Button>
                        </div>
                    ) : 

                        <Elements stripe={stripePromise} options={options}>
                            <div style={{
                                backgroundColor: 'white',
                                padding: 25
                            }}>
                                <Typography style={{marginTop: 16, marginBottom: 36, textAlign: 'center'}} gutterBottom variant='h4'>
                                    Checkout
                                </Typography>
                                <Typography color='primary' style={{marginTop: 16, marginBottom: 36, textAlign: 'center'}} gutterBottom variant='h4'>
                                    ${state.rentalStatus === 'true' ? '59.99' : '29.99' }
                                </Typography>

                                <Checkout
                                    submitFormHandler={submitFormHandler}
                                    handleSubmit={handleSubmit} 
                                    paymentDone={paymentDone}
                                />
                            </div>
                        </Elements> 
                    }
                </Modal>
            </Box>
        </Box>
    );
};

View.propTypes = {
    inputFields: PropTypes.object.isRequired,
    inputFieldsForCustomerData: PropTypes.object.isRequired,
    alert: PropTypes.any.isRequired,
    submitFormHandler: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    control: PropTypes.any.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    payNowCloseHandler: PropTypes.func.isRequired
};
