import {
    APPOINTMENT_START,
    GET_APPOINTMENT_BY_USER_START,
    UPDATE_APPOINTMENT_START,
} from '../actionType';

export const appointmentAction = () => ({
    type: APPOINTMENT_START,
});

export const updateAppointmentAction = (id, data) => ({
    type: UPDATE_APPOINTMENT_START,
    id,
    data,
});

export const getAppointmentByUserAction = (userId) => ({
    type: GET_APPOINTMENT_BY_USER_START,
    userId,
});
