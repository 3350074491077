import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useStyles } from '../../styles';
import { Button, TextField, CssBaseline, Container, Grid, Link as MLink } from '@material-ui/core';
import logo from '../../assets/img/logoNextLogin.png';
import { emailValidation } from '../../utility/regex';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { forgotPasswordAction } from '../../redux/actions/loginAction';

const Index = (props) => {
    const { history } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [email, setEmail] = useState('');

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    };
    const handleSubmit = (e) => {
        if (emailValidation.test(email)) {
            dispatch(forgotPasswordAction({ email }));
        }
    };

    const onEnter = (e) => {
        if (e.key == 'Enter') {
            handleSubmit();
        }
    };
    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <div className={classes.paper_SignIn}>
                <img src={logo} alt='Logo' />
                <div className={classes.form_SignIn} noValidate onKeyDown={onEnter}>
                    <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        id='email'
                        label='Email'
                        name='Email'
                        autoComplete='email'
                        value={email}
                        onChange={onChangeEmail}
                        autoFocus
                    />
                    <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        className={classes.submit_SignIn}
                        onClick={handleSubmit}
                    >
                        Send
                    </Button>
                    <Grid item xs>
                        <MLink component={Link} to='/sign-in' variant='body2'>
                            Return to Log In
                        </MLink>
                    </Grid>
                </div>
            </div>
        </Container>
    );
};

export default withRouter(Index);
