import React from 'react';
import {
    DialogContent,
    DialogTitle,
    Slide,
    Typography,
    IconButton,
    Input,
    CircularProgress,
    Checkbox,
} from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import Logo from '../../../../img/logo-next-dashboard.png';

import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export class DataToPrint extends React.Component {
    render() {
        const {
            appointment,
            vehicle,
            dataAppoinment,
            onClose,
            loading,
            editable,
            handleOnChangesAddress,
            onAppointment,
            handleChangeCheckbox,
            imgs,
            setOpenModalLicense,
            printOut,
        } = this.props;

        return (
            <>
                <div style={{ textAlign: 'center', marginTop: '40px' }}>
                    <img src={Logo} style={{ width: '140px' }} />
                </div>

                <DialogTitle style={{ paddingBottom: 0 }}>
                    <Typography variant='h6'>Detail Appointment</Typography>
                    <IconButton
                        style={{ position: 'absolute', right: 4, top: 4 }}
                        onClick={onClose}
                        size='small'
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                {vehicle ? (
                    <DialogContent>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                paddingTop: 3,

                                marginBottom: '50px',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: '1',
                                    width: 200,
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1,
                                        }}
                                    >
                                        <Typography variant='subtitle2'> Client: </Typography>
                                        <Typography variant='caption' style={{ marginTop: 2 }}>
                                            {' '}
                                            {appointment.userName}{' '}
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1,
                                        }}
                                    >
                                        <Typography variant='subtitle2'> Phone: </Typography>
                                        <Typography variant='caption' style={{ marginTop: 2 }}>
                                            {' '}
                                            {appointment.phone_number}{' '}
                                        </Typography>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-around',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1,
                                        }}
                                    >
                                        <Typography variant='subtitle2'> Make: </Typography>
                                        <Typography variant='caption' style={{ marginTop: 2 }}>
                                            {' '}
                                            {vehicle.make}{' '}
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1,
                                        }}
                                    >
                                        <Typography variant='subtitle2'> Model: </Typography>
                                        <Typography variant='caption' style={{ marginTop: 2 }}>
                                            {' '}
                                            {vehicle.model}{' '}
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1,
                                        }}
                                    >
                                        <Typography variant='subtitle2'> color: </Typography>
                                        <Typography variant='caption' style={{ marginTop: 2 }}>
                                            {' '}
                                            {vehicle.color}{' '}
                                        </Typography>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'left',
                                    }}
                                >
                                    <Typography variant='subtitle2'> VIN: </Typography>
                                    <Typography variant='caption' style={{ marginTop: 2 }}>
                                        {' '}
                                        {vehicle.vin}{' '}
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography
                                        variant='subtitle1'
                                        style={{ flex: 1, marginTop: 2 }}
                                    >
                                        {' '}
                                        Street :{' '}
                                    </Typography>
                                    <Input
                                        style={{ flex: 2, marginLeft: 10, marginRight: 20 }}
                                        disabled={loading || !editable}
                                        name='street'
                                        value={dataAppoinment.pickup_address.street}
                                        onChange={handleOnChangesAddress}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography
                                        variant='subtitle1'
                                        style={{ flex: 1, marginTop: 2 }}
                                    >
                                        {' '}
                                        Street 2 :{' '}
                                    </Typography>
                                    <Input
                                        style={{ flex: 2, marginLeft: 10, marginRight: 20 }}
                                        disabled={loading || !editable}
                                        name='street_2'
                                        value={dataAppoinment.pickup_address.street_2}
                                        onChange={handleOnChangesAddress}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography
                                        variant='subtitle1'
                                        style={{ flex: 1, marginTop: 2 }}
                                    >
                                        {' '}
                                        State :{' '}
                                    </Typography>
                                    <Input
                                        style={{ flex: 2, marginLeft: 10, marginRight: 20 }}
                                        disabled={loading || !editable}
                                        name='state'
                                        value={dataAppoinment.pickup_address.state}
                                        onChange={handleOnChangesAddress}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography
                                        variant='subtitle1'
                                        style={{ flex: 1, marginTop: 2 }}
                                    >
                                        {' '}
                                        City :{' '}
                                    </Typography>
                                    <Input
                                        style={{ flex: 2, marginLeft: 10, marginRight: 20 }}
                                        disabled={loading || !editable}
                                        name='city'
                                        value={dataAppoinment.pickup_address.city}
                                        onChange={handleOnChangesAddress}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography
                                        variant='subtitle1'
                                        style={{ flex: 1, marginTop: 2 }}
                                    >
                                        {' '}
                                        Zip Code :{' '}
                                    </Typography>
                                    <Input
                                        style={{ flex: 2, marginLeft: 10, marginRight: 20 }}
                                        disabled={loading || !editable}
                                        name='zip'
                                        value={dataAppoinment.pickup_address.zip}
                                        onChange={handleOnChangesAddress}
                                    />
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography
                                        variant='subtitle1'
                                        style={{ flex: 1, marginTop: 2 }}
                                    >
                                        {' '}
                                        Date :{' '}
                                    </Typography>
                                    <Input
                                        style={{ flex: 2, marginLeft: 10, marginRight: 20 }}
                                        disabled={loading || !editable}
                                        name='format_pickup_date'
                                        type='date'
                                        value={dataAppoinment.format_pickup_date}
                                        onChange={onAppointment}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography
                                        variant='subtitle1'
                                        style={{ flex: 1, marginTop: 2 }}
                                    >
                                        {' '}
                                        Time :{' '}
                                    </Typography>
                                    <Input
                                        style={{ flex: 2, marginLeft: 10, marginRight: 20 }}
                                        disabled={loading || !editable}
                                        name='pickup_time'
                                        type='time'
                                        value={dataAppoinment.pickup_time}
                                        onChange={onAppointment}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography
                                        variant='subtitle1'
                                        style={{ flex: 1, marginTop: 2 }}
                                    >
                                        {' '}
                                        Rent Vehicle :{' '}
                                    </Typography>
                                    <Checkbox
                                        disabled={loading || !editable}
                                        checked={dataAppoinment.rent_vehicle}
                                        value={dataAppoinment.rent_vehicle}
                                        onChange={handleChangeCheckbox}
                                    />
                                    {/**  <Input
                                    style={{ flex: 2, marginLeft: 10, marginRight: 20 }}
                                    disabled={loading || !editable}
                                    name="rent_vehicle"
                                    type='checkbox'
                                    value={dataAppoinment.rent_vehicle}
                                    onChange={onAppointment}
                                    
                                />
                                */}
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flex: 1 }}>
                            {Object.keys(imgs || '')?.length > 0 && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-around',
                                    }}
                                >
                                    {imgs.hasOwnProperty('driver_license_img') && (
                                        <img
                                            src={`data:image/jpg;base64,${imgs.driver_license_img}`}
                                            alt='License ID'
                                            style={{
                                                width: '300px',
                                                height: '400px',
                                                objectFit: 'cover',
                                            }}
                                            onClick={() =>
                                                setOpenModalLicense({
                                                    open: true,
                                                    img: imgs.driver_license_img,
                                                })
                                            }
                                        />
                                    )}
                                    {imgs.hasOwnProperty('insurance_id_img') && (
                                        <img
                                            src={`data:image/jpg;base64,${imgs.insurance_id_img}`}
                                            alt='License ID'
                                            style={{
                                                width: '300px',
                                                height: '400px',
                                                objectFit: 'cover',
                                            }}
                                            onClick={() =>
                                                setOpenModalLicense({
                                                    open: true,
                                                    img: imgs.insurance_id_img,
                                                })
                                            }
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </DialogContent>
                ) : (
                    <CircularProgress
                        size={24}
                        color='primary'
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                        }}
                    />
                )}
            </>
        );
    }
}
