import { put, call, takeLatest, select } from 'redux-saga/effects';
import { throwError } from '../../utility/error';

import { USER } from '../../utility/urls';
import { isLoadingAppAction, snackbarAction } from '../actions/utilityAction';

import { FETCH_USER_START, FETCH_USER_COMPLETE } from '../actionType';
import { apiCall } from '../http/apiCall';
import { tokenSelector } from '../selector';

function* fetchUsers() {
    yield put(isLoadingAppAction(true));
    try {
        const token = yield select(tokenSelector);
        const userResult = yield call(apiCall, USER, null, token, 'GET');
        yield put({ type: FETCH_USER_COMPLETE, usersList: userResult.data });
    } catch (error) {
        yield put(throwError(error));
    }
    yield put(isLoadingAppAction(false));
}

export default function* usersWatch() {
    yield takeLatest(FETCH_USER_START, fetchUsers);
}
