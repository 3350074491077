import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import View from './view';

import { loginAction } from '../../redux/actions/loginAction';
import { snackbarAction } from '../../redux/actions/utilityAction';
import { tokenSelector } from '../../redux/selector';

const Index = (props) => {
    const { history } = props;
    const dispatch = useDispatch();
    const token = useSelector((state) => tokenSelector(state));
    const [userData, setUserData] = useState({
        username: '',
        password: '',
    });
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (token) {
            history.push('/main');
        }
    }, [token]);

    const onChangeLogin = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value,
        });
    };

    const handleOnSummit = () => {
        if (!(userData.username.trim() && userData.password)) {
            dispatch(
                snackbarAction({
                    open: true,
                    type: 'error',
                    message: 'empty field',
                })
            );

            return;
        }

        dispatch(
            loginAction({
                ...userData,
                username: userData.username.trim(),
            })
        );
    };

    const onEnter = (e) => {
        if (e.key == 'Enter') {
            handleOnSummit();
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword((showPassword) => !showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <View
            loginState={userData}
            onChangeLogin={onChangeLogin}
            handleOnSummit={handleOnSummit}
            onEnter={onEnter}
            showPassword={showPassword}
            handleClickShowPassword={handleClickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
        />
    );
};

export default withRouter(Index);
