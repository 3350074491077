import { FETCH_USER_START, IS_LOADING_USER } from '../actionType';

export const userAction = () => ({
    type: FETCH_USER_START,
});

export const isLoadingUserDataAction = (status) => {
    return {
        type: IS_LOADING_USER,
        isLoadingUserData: status,
    };
};
