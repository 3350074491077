import React, { useContext } from 'react';
import {
    Button,
    Checkbox,
    Box,
    Typography,
    Link,
    makeStyles,
    CircularProgress,
    Divider,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

import { ScheduleData, AGREEMENT } from '../../context';

// Styles
const useStyles = makeStyles((theme) => ({
    container: {
        margin: '35px 0',
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 16
    },
    checkbox: {
        marginLeft: '-10px',
    },
    actions: {
        marginTop: '40px',
    },
    cancel: {
        marginLeft: '10px',
    },
    alert: {
        marginTop: theme.spacing(2),
    },
    spinner: {
        width: '150px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export const BookReservation = ({ submitFormHandler, alert, handleSubmit, isLoading }) => {
    const { state, dispatch } = useContext(ScheduleData);

    const classes = useStyles();

    const handleChange = (event) => {
        dispatch({ type: AGREEMENT, payload: event.target.checked });
    };

    let spinner = (
        <Box className={classes.spinner}>
            <CircularProgress color='secondary' size={20} />
        </Box>
    );

    return (
        <div className={classes.container}>

            <Divider />

            <Box className={classes.checkboxContainer}>
                <Checkbox
                    classes={{ root: classes.checkbox }}
                    checked={state.agreement}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Typography>
                    I agree to the <Link to='#'>terms and conditions. Privacy</Link>
                </Typography>
            </Box>

            {alert && (
                <Alert className={classes.alert} severity={alert.type}>
                    {alert.message}
                </Alert>
            )}

            <Box className={classes.actions}>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={handleSubmit(submitFormHandler)}
                    disabled={state.isLocationInsideRadius}
                >
                    {isLoading ? spinner : 'Book Reservation'}
                </Button>
                <Button className={classes.cancel}>Cancel</Button>
            </Box>
        </div>
    );
};

BookReservation.propTypes = {
    alert: PropTypes.any.isRequired,
    submitFormHandler: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};
