import { get } from 'lodash';

import { GET_LICENSE_ID_COMPLETE } from '../actionType';

const initialState = {
    licenseID: null,
};

export const licenseIDReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LICENSE_ID_COMPLETE:
            return {
                ...state,
                licenseID: get(action, 'licenseID'),
            };

        default:
            return state;
    }
};
