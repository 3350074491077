import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styles from '../../../assets/styles/parallaxStyle';

const useStyles = makeStyles(styles);

export default function Parallax(props) {
    const { filter, className, children, style, small } = props;
    const classes = useStyles();
    const screen720 = useMediaQuery('(min-width:720px)');
    const screen300 = useMediaQuery('(max-width:720px)');

    let windowScrollTop;
    if (window.innerWidth >= 768) {
        windowScrollTop = window.pageYOffset / 3;
    } else {
        windowScrollTop = 0;
    }

    const [transform, setTransform] = useState('translate3d(0,' + windowScrollTop + 'px,0)');

    useEffect(() => {
        if (window.innerWidth >= 768) {
            window.addEventListener('scroll', resetTransform);
        }
        return function cleanup() {
            if (window.innerWidth >= 768) {
                window.removeEventListener('scroll', resetTransform);
            }
        };
    });

    const resetTransform = () => {
        var windowScrollTop = window.pageYOffset / 3;
        setTransform('translate3d(0,' + windowScrollTop + 'px,0)');
    };

    const parallaxClasses = classNames({
        [classes.parallax]: true,
        [classes.filter]: filter,
        [classes.small]: small,
        [className]: className !== undefined,
        [classes.img300]: screen300,
        [classes.img720]: screen720,
    });
    return (
        <div
            className={parallaxClasses}
            style={{
                ...style,
                transform: transform,
            }}
        >
            {children}
        </div>
    );
}

Parallax.propTypes = {
    className: PropTypes.string,
    filter: PropTypes.bool,
    children: PropTypes.node,
    style: PropTypes.string,
    image: PropTypes.any,
    small: PropTypes.bool,
};
