import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, Slide, Button, IconButton, Modal } from '@material-ui/core';
import ReactToPrint from 'react-to-print';

import { DataToPrint } from '../DataToPrint/DataToPrint';
import { DetailAppointment } from './DetailAppointment';
import { dateValidation } from '../../../../utility/regex';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { appointmentSelector, licenseIDSelector } from '../../../../redux/selector';
import { snackbarAction } from '../../../../redux/actions/utilityAction';
import { updateAppointmentAction } from '../../../../redux/actions/appointmentAction';
import { licenseIDAction } from '../../../../redux/actions/licenseIDAction';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const Index = (props) => {
    const dispatch = useDispatch();
    const { idAppointment, open, handleClose, vehicle } = props;
    const licenseID = useSelector((state) => licenseIDSelector(state));

    const [loading, setLoading] = useState(false);
    const [editable, setEditable] = useState(false);
    const appointmentList = useSelector((state) => appointmentSelector(state));
    const componentRef = useRef();
    const [printOut, setPrintOut] = useState(false);

    const [dataAppoinment, setDataAppoitment] = useState({
        vehicle_id: '',
        pickup_address: {},
        pickup_date: '',
        pickup_time: '',
        format_pickup_date: '',
        rent_vehicle: false,
    });

    const [openModalLicense, setOpenModalLicense] = useState({
        open: false,
        img: '',
    });

    const appointment = appointmentList.find((obj) => {
        return obj._id === idAppointment;
    });

    useEffect(() => {
        open && getImgs(appointment.user_id);
        let splitPickupDate = appointment.pickup_date.split('-');
        setDataAppoitment({
            vehicle_id: appointment.vehicle_id,
            pickup_address: appointment.pickup_address,
            pickup_date: appointment.pickup_date,
            pickup_time: appointment.pickup_time,
            format_pickup_date: `${splitPickupDate[2]}-${splitPickupDate[0]}-${splitPickupDate[1]}`,
            rent_vehicle: appointment.hasOwnProperty('rent_vehicle')
                ? appointment.rent_vehicle
                : false,
        });
    }, [appointment, open]);

    const getImgs = async (userId) => {
        dispatch(licenseIDAction(userId));
    };

    const onAppointment = (e) => {
        if (e.target.name === 'format_pickup_date') {
            let splitValue = e.target.value.split('-');
            setDataAppoitment({
                ...dataAppoinment,
                [e.target.name]: e.target.value,
                pickup_date: `${splitValue[1]}-${splitValue[2]}-${splitValue[0]}`,
            });
        } else {
            setDataAppoitment({
                ...dataAppoinment,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleChangeCheckbox = (event) => {
        setDataAppoitment({
            ...dataAppoinment,
            rent_vehicle: event.target.checked,
        });
    };

    const handleOnChangesAddress = (e) => {
        setDataAppoitment({
            ...dataAppoinment,
            pickup_address: {
                ...dataAppoinment.pickup_address,
                [e.target.name]: e.target.value,
            },
        });
    };

    const onClose = () => {
        setEditable(false);
        handleClose(false, null);
    };

    const onSave = async () => {
        setLoading(true);
        if (dateValidation.test(dataAppoinment.pickup_date)) {
            const { format_pickup_date, ...other } = dataAppoinment;

            dispatch(updateAppointmentAction(idAppointment, other));
            onClose();
            setLoading(false);
            return;
        }
        setLoading(false);
        dispatch(
            snackbarAction({
                open: true,
                msg: ' date format error, mm-dd-yyyy ',
                type: 'error',
            })
        );
    };

    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={() => onClose()}>
            <DetailAppointment
                appointment={appointment}
                vehicle={vehicle}
                dataAppoinment={dataAppoinment}
                onClose={onClose}
                loading={loading}
                printOut={printOut}
                editable={editable}
                handleOnChangesAddress={handleOnChangesAddress}
                onAppointment={onAppointment}
                handleChangeCheckbox={handleChangeCheckbox}
                imgs={licenseID}
                setOpenModalLicense={setOpenModalLicense}
            />

            {vehicle ? (
                <DialogActions>
                    <ReactToPrint
                        trigger={() => {
                            return <Button>Print out</Button>;
                        }}
                        onBeforeGetContent={() => {
                            setPrintOut(true);
                        }}
                        onAfterPrint={() => {
                            setPrintOut(false);
                        }}
                        content={() => componentRef.current}
                    />
                    <Button
                        disabled={loading}
                        onClick={() => (editable ? onSave() : setEditable(true))}
                    >
                        {editable ? 'Save' : 'Edit'}
                    </Button>
                </DialogActions>
            ) : null}

            <div style={{ display: 'none' }}>
                <div ref={componentRef}>
                    <DataToPrint
                        appointment={appointment}
                        vehicle={vehicle}
                        dataAppoinment={dataAppoinment}
                        onClose={onClose}
                        loading={loading}
                        printOut={printOut}
                        editable={editable}
                        handleOnChangesAddress={handleOnChangesAddress}
                        onAppointment={onAppointment}
                        handleChangeCheckbox={handleChangeCheckbox}
                        imgs={licenseID}
                        setOpenModalLicense={setOpenModalLicense}
                    />
                </div>
            </div>

            <Modal
                open={openModalLicense.open}
                onClose={() => setOpenModalLicense({ open: false, img: '' })}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <>
                    <IconButton
                        style={{ position: 'absolute', top: 5, right: 8 }}
                        onClick={() => setOpenModalLicense({ open: false, img: '' })}
                    >
                        <HighlightOffIcon style={{ color: 'white', fontSize: 40 }} />
                    </IconButton>
                    <img
                        src={`data:image/jpg;base64,${openModalLicense.img}`}
                        alt='License ID'
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            resizeMode: 'stretch',
                        }}
                    />
                </>
            </Modal>
        </Dialog>
    );
};

Index.propTypes = {
    idAppointment: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    vehicle: PropTypes.object.isRequired,
};

export default Index;
