import { get } from 'lodash';

import {
    VEHICLE_LIST,
    FETCH_VEHICLE_BY_ID_COMPLETE,
    GET_USER_VEHICLE_LIST_COMPLETE,
} from '../actionType';

const initialState = {
    vehiclesList: [],
    userVehicleList: null,
    singleVehicle: null,
};

export const vehicleReducer = (state = initialState, action) => {
    switch (action.type) {
        case VEHICLE_LIST:
            return {
                ...state,
                vehiclesList: get(action, 'vehiclesList'),
            };

        case FETCH_VEHICLE_BY_ID_COMPLETE:
            return {
                ...state,
                singleVehicle: get(action, 'singleVehicle'),
            };
        case GET_USER_VEHICLE_LIST_COMPLETE:
            return {
                ...state,
                userVehicleList: get(action, 'userVehicleList'),
            };
        default:
            return state;
    }
};
