import axios from 'axios';

import { BASE_URL } from '../../utility/urls';
import {
    rmLocalStorage,
    setLocalStorage,
    REFRESH_TOKEN,
    getLocalStorage,
} from '../../utility/localStorage';

/** Refresh Token Implementation */

axios.interceptors.response.use(
    (response) => response,
    (error) => refreshTokenAndReAttempFunction(error)
);

const refreshTokenAndReAttempFunction = (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && originalRequest.url === `${BASE_URL}refresh_token`) {
        rmLocalStorage();
        rmLocalStorage(REFRESH_TOKEN);
        window.location.href = '/';
        return Promise.reject(error);
    }

    if (error.response?.status === 401 && !originalRequest.retry) {
        originalRequest.retry = true;
        const refreshToken = getLocalStorage(REFRESH_TOKEN);

        return axios
            .post(`${BASE_URL}refresh_token`, null, {
                headers: { Authorization: `JWT ${refreshToken}` },
            })
            .then((res) => {
                if (res.status === 200) {
                    const accessToken = res.data.access_token;
                    setLocalStorage(accessToken);
                    originalRequest.headers['Authorization'] = 'JWT ' + accessToken;
                    return axios(originalRequest);
                }
            });
    }
    return Promise.reject(error);
};

export const apiCall = (url, data, token, method, headers, params, another_url) =>
    axios({
        url: another_url ? another_url : BASE_URL + url,
        method,
        data,
        params,
        headers: {
            ...headers,
            Authorization: 'JWT ' + token,
            'Content-Type': 'application/json',
        },
    });
