import { get } from 'lodash';
import { SNACKBAR, IS_LOADING_APP } from '../actionType';

const initialState = {
    isLoadingApp: false,
    snackbar: {
        open: false,
        type: null,
        message: '',
    },
};

export const utilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case IS_LOADING_APP:
            return {
                ...state,
                isLoadingApp: get(action, 'isLoadingApp'),
            };
        case SNACKBAR:
            return {
                ...state,
                snackbar: get(action, 'snackbar'),
            };
        default:
            return state;
    }
};
