import { put, select, call, takeLatest } from 'redux-saga/effects';

import { isLoadingAppAction, snackbarAction } from '../actions/utilityAction';
import { isLoadingUserDataAction } from '../actions/userAction';
import { apiCall } from '../http/apiCall';
import { tokenSelector } from '../selector';
import { USER_DRIVER_LICENSE } from '../../utility/urls';
import { GET_LICENSE_ID_START, GET_LICENSE_ID_COMPLETE } from '../actionType';
import { throwError } from '../../utility/error';

function* fetchLicenseID({ id }) {
    try {
        const token = yield select(tokenSelector);

        const licenseIDResult = yield call(
            apiCall,
            `${USER_DRIVER_LICENSE}/${id}`,
            null,
            token,
            'GET'
        );

        yield put({ type: GET_LICENSE_ID_COMPLETE, licenseID: licenseIDResult.data });
    } catch (error) {
        yield put(throwError(error));
    }
    yield put(isLoadingUserDataAction(false)); // for the user selecting only
}

export default function* licenseWatch() {
    yield takeLatest(GET_LICENSE_ID_START, fetchLicenseID);
}
