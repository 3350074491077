import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import { useHistory } from 'react-router-dom';

export const MainListItems = () => {
    let history = useHistory();
    const routers = [
        {
            onclick: () => history.push('/main'),
            icon: <DashboardIcon style={{ color: '#FFFFFF' }} />,
            name: 'Main',
        },
        {
            onclick: () => history.push('/users'),
            icon: <PersonIcon style={{ color: '#FFFFFF' }} />,
            name: 'Users',
        },
    ];
    return (
        <div>
            {routers.map((router, index) => {
                return (
                    <ListItem button onClick={router.onclick} key={index}>
                        <ListItemIcon>{router.icon}</ListItemIcon>
                        <ListItemText primary={router.name} style={{ color: '#FFFFFF' }} />
                    </ListItem>
                );
            })}
        </div>
    );
};
