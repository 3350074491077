import { all } from 'redux-saga/effects';

import loginSaga from './loginSaga';
import localStorageSaga from './localStorageSaga';
import appointmentSaga from './appointmentSaga';
import licenseSaga from './licenseSaga';
import vehicleSaga from './vehicleSaga';
import usersSaga from './userListSaga';
import notificationSaga from './notificationSaga';

export default function* rootSaga() {
    yield all([
        localStorageSaga(),
        loginSaga(),
        appointmentSaga(),
        licenseSaga(),
        vehicleSaga(),
        usersSaga(),
        notificationSaga(),
    ]);
}
