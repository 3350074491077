/** User Login */
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_COMPLETE = 'LOGIN_COMPLETE';
export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_COMPLETE = 'LOGOUT_COMPLETE';
export const FORGOT_PASSWORD_USER = 'FORGOT_PASSWORD_USER';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const IS_RESET_TOKEN_VALID = 'IS_RESET_TOKEN_VALID';
export const RESET_PASSWORD_SNACKBAR = 'RESET_PASSWORD_SNACKBAR';

/** Utility Reducer */
export const SNACKBAR = 'SNACKBAR';
export const IS_LOADING_APP = 'IS_LOADING_APP';

/** Appointments */
export const APPOINTMENT_START = 'APPOINTMENT_START';
export const APPOINTMENT_COMPLETE = 'APPOINTMENT_COMPLETE';
export const VEHICLE_LIST = 'VEHICLE_LIST';
export const USER_LIST = 'USER_LIST';
export const UPDATE_APPOINTMENT_START = 'UPDATE_APPOINTMENT_START';
export const GET_APPOINTMENT_BY_USER_START = 'GET_APPOINTMENT_BY_USER_START';
export const GET_APPOINTMENT_BY_USER_COMPLETE = 'GET_APPOINTMENT_BY_USER_COMPLETE';

/** License ID */
export const GET_LICENSE_ID_START = 'GET_LICENSE_ID_START';
export const GET_LICENSE_ID_COMPLETE = 'GET_LICENSE_ID_COMPLETE';

/** Vehciles */
export const GET_USER_VEHICLE_LIST_START = 'GET_USER_VEHICLE_LIST_START';
export const GET_USER_VEHICLE_LIST_COMPLETE = 'GET_USER_VEHICLE_LIST_COMPLETE';
export const FETCH_VEHICLE_BY_ID_START = 'FETCH_VEHICLE_BY_ID_START';
export const FETCH_VEHICLE_BY_ID_COMPLETE = 'FETCH_VEHICLE_BY_ID_COMPLETE';

/** Users */
export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_COMPLETE = 'FETCH_USER_COMPLETE';
export const IS_LOADING_USER = 'IS_LOADING_USER';

/** Notification */
export const NOTIFICATION_SEND_TO_USER = 'NOTIFICATION_SEND_TO_USER';
