import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

/** Style Component */
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    browser: {
        border: `1px solid ${theme.palette.secondary.light}`,
        borderRadius: '5px',
        width: 450,
        height: 380,
    },
    browser__header: {
        height: 30,
        background: theme.palette.primary.light,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
    },
    dot: {
        marginLeft: 4,
        width: 10,
        height: 10,
        background: 'red',
        borderRadius: 5,
    },
    browser__body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',

        '& > h1': {
            fontSize: '40px',
            color: theme.palette.error.dark,
        },
    },
    browser__button: {
        background: 'transparent',
        marginTop: '100px',
        border: '0px solid transparent',
        fontSize: '15px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.success.main,
    },
}));

export const NotfoundPage = () => {
    const classes = useStyles();
    const history = useHistory();

    const takeMeToHome = () => () => {
        history.push('/');
    };

    return (
        <div className={classes.root}>
            <div className={classes.browser}>
                <div className={classes.browser__header}>
                    <div className={classes.dot}></div>
                    <div className={classes.dot}></div>
                    <div className={classes.dot}></div>
                </div>
                <div className={classes.browser__body}>
                    <h1>404 :(</h1>

                    <button className={classes.browser__button} onClick={takeMeToHome()}>
                        <ArrowBackIcon className={classes.arrow} /> Go home
                    </button>
                </div>
            </div>
        </div>
    );
};
