import React from 'react';
import { withGoogleMap, GoogleMap, Marker, Circle } from 'react-google-maps';

const MapConfig = withGoogleMap((props) => (
    <GoogleMap
        key={new Date().getTime()}
        defaultZoom={8}
        defaultCenter={{ lat: props.lat, lng: props.lng }}
    >
        <Marker position={{ lat: props.lat, lng: props.lng }} />
        <Circle center={{ lat: 25.4844904, lng: -80.4616389 }} radius={80467.2} />
    </GoogleMap>
));

export const Map = React.memo(({ ...rest }) => {
    return <MapConfig {...rest} />;
}, areEqual);

function areEqual(prevState, nextState) {
    return prevState.lat === nextState.lat && prevState.lng === nextState.lng;
}
