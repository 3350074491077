import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem } from '@material-ui/core';
import styles from '../../assets/styles/headerLinksStyle';
import apple from '../../assets/img/AppStore.png';
import google from '../../assets/img/PlayStore.png';

const useStyles = makeStyles(styles);

export const AppDownloads = ({ logo }) => {
    const classes = useStyles();

    return (
        <>
            <ListItem>
                <div>
                    <a href='https://www.nextcarservices.com/'>
                        <img src={logo} style={{ maxWidth: 100 }} />
                    </a>
                </div>
            </ListItem>
            <ListItem className={classes.listItem}>
                <div>
                    <a href='https://apps.apple.com/us/app/next-service/id1377377828'>
                        <img src={apple} style={{ maxWidth: 100 }} />
                    </a>
                </div>
            </ListItem>
            <ListItem className={classes.listItem}>
                <div>
                    <a href='https://play.google.com/store/apps/details?id=com.heydaydev.next'>
                        <img src={google} style={{ maxWidth: 100 }} />
                    </a>
                </div>
            </ListItem>
        </>
    );
};
