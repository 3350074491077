import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from '../../styles';
import {
    makeStyles,
    Button,
    TextField,
    CssBaseline,
    Container,
    Grid,
    Link as MLink,
    Paper,
    Typography,
} from '@material-ui/core';
import logo from '../../assets/img/logoNextLogin.png';
import { useParams, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Error as ErrorIcon, CheckCircle as CheckCircleIcon } from '@material-ui/icons';

import { resetPasswordAction } from '../../redux/actions/loginAction';
import {
    isLoadingAppSelector,
    resetSnackbarSelector,
    snackbarSelector,
} from '../../redux/selector';
import { snackbarAction } from '../../redux/actions/utilityAction';
import { isResetTokenValidSelector } from '../../redux/selector';

/** Styling Component */
const useStylesLocal = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(3),
    },
    iconSizeError: {
        fontSize: '5rem',
        color: theme.palette.error.main,
        marginBottom: theme.spacing(4),
    },
    iconSizeSuccess: {
        fontSize: '5rem',
        color: theme.palette.success.main,
        marginBottom: theme.spacing(4),
    },
    centerDiv: {
        textAlign: 'center',
        padding: theme.spacing(3),
    },
}));

export const ResetPassword = (props) => {
    const classes = useStyles();
    const classesLocal = useStylesLocal();
    const dispatch = useDispatch();
    const isResetTokenValid = useSelector((state) => isResetTokenValidSelector(state));
    const isLoading = useSelector((state) => isLoadingAppSelector(state));
    const resetSnackbar = useSelector((state) => resetSnackbarSelector(state));
    const { reset_token } = useParams();
    const search = useLocation().search;
    const [isNextDriver, setIsNextDriver] = useState(null);

    const params = useParams();
    const passwordRef = useRef(null);
    const confirmPasswordRef = useRef(null);

    const resetInputFields = {
        Password: {
            name: 'New Passowrd',
            ref: passwordRef,
        },
        ConfirmPassword: {
            name: 'Confirm Password',
            ref: confirmPasswordRef,
        },
    };

    useEffect(() => {
        const nextDriverURL = new URLSearchParams(search).get('service');

        setIsNextDriver(nextDriverURL);
        dispatch(resetPasswordAction(reset_token, 'GET', nextDriverURL));
    }, []);

    const handleSubmit = (e) => {
        const password = passwordRef.current.value;
        const confirmPassword = confirmPasswordRef.current.value;

        if (password && confirmPassword && password === confirmPassword) {
            dispatch(resetPasswordAction({ password, reset_token }, 'POST', isNextDriver));
            return;
        }

        dispatch(
            snackbarAction({
                open: true,
                type: 'error',
                message: 'Both password does not match.',
            })
        );
    };

    const onEnter = (e) => {
        if (e.key == 'Enter') {
            handleSubmit();
        }
    };

    let newPasswordForm = (
        <Paper className={classesLocal.paper}>
            <div className={classesLocal.centerDiv}>
                {isLoading && 'Loading...'}
                {resetSnackbar.open && !isLoading && (
                    <>
                        {resetSnackbar.type === 'success' ? (
                            <CheckCircleIcon
                                fontSize='large'
                                classes={{ fontSizeLarge: classesLocal.iconSizeSuccess }}
                            />
                        ) : (
                            <ErrorIcon
                                fontSize='large'
                                classes={{ fontSizeLarge: classesLocal.iconSizeError }}
                            />
                        )}
                        <Typography variant='subtitle1'>{resetSnackbar.message}</Typography>
                        <MLink component={Link} to='/sign-in'>
                            Back to Log in
                        </MLink>
                    </>
                )}
            </div>
        </Paper>
    );

    if (!isLoading && isResetTokenValid)
        newPasswordForm = (
            <>
                {Object.keys(resetInputFields).map((item) => (
                    <TextField
                        key={item}
                        type='password'
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        id='email'
                        label={resetInputFields[item].name}
                        name={resetInputFields[item].name}
                        inputRef={resetInputFields[item].ref}
                        autoFocus
                    />
                ))}
                <Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.submit_SignIn}
                    onClick={handleSubmit}
                >
                    submit
                </Button>
                <Grid item xs>
                    <MLink component={Link} to='/sign-in' variant='body2'>
                        Return to Log In
                    </MLink>
                </Grid>
            </>
        );

    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <div className={classes.paper_SignIn}>
                <img src={logo} alt='Logo' />
                <div className={classes.form_SignIn} noValidate onKeyDown={onEnter}>
                    {newPasswordForm}
                </div>
            </div>
        </Container>
    );
};
