import React from 'react';
import { makeStyles, TextField } from '@material-ui/core/';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
    inputText: {
        marginTop: theme.spacing(3),
        width: ({ width }) => width || '100%',
    },
}));

export const Input = ({ name, label, control, width }) => {
    const classes = useStyles({ width });

    return (
        <Controller
            name={name.toLowerCase()}
            control={control}
            defaultValue=''
            render={({ field: { onChange, value } }) => (
                <TextField
                    className={classes.inputText}
                    value={value}
                    label={label}
                    onChange={onChange}
                    variant='outlined'
                    size='small'
                />
            )}
        />
    );
};

Input.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};
