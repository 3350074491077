import { get } from 'lodash';
import {
    LOGIN_COMPLETE,
    LOGOUT_COMPLETE,
    IS_RESET_TOKEN_VALID,
    RESET_PASSWORD_SNACKBAR,
} from '../actionType';

const initialState = {
    token: null,
    isResetTokenValid: false,
    resetSnackbar: {
        open: false,
        type: null,
        message: '',
    },
};

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_COMPLETE:
            return {
                ...state,
                token: get(action, 'token'),
            };

        case LOGOUT_COMPLETE:
            return {
                ...state,
                token: null,
            };
        case IS_RESET_TOKEN_VALID:
            return {
                ...state,
                isResetTokenValid: get(action, 'isResetTokenValid'),
            };
        case RESET_PASSWORD_SNACKBAR:
            return {
                ...state,
                resetSnackbar: get(action, 'resetSnackbar'),
            };
        default:
            return state;
    }
};
