import { IS_LOADING_APP, SNACKBAR } from '../actionType';

export const snackbarAction = (snackbar) => ({
    type: SNACKBAR,
    snackbar,
});

export const isLoadingAppAction = (isLoadingApp) => ({
    type: IS_LOADING_APP,
    isLoadingApp,
});
