import React from 'react';
import { Typography, makeStyles, Box } from '@material-ui/core/';

const useStyles = makeStyles({
    typography: {
        fontWeight: ({ bold }) => bold,
        fontFamily: ({ font }) => (font === 'primary' ? 'ToyotaType' : 'ArialCondense'),
        fontSize: ({ size }) => size,
    },
});

export const Title = ({ title, variant, bold, font, size }) => {
    const classes = useStyles({ bold, font, size });

    return (
        <Typography variant={variant} gutterBottom className={classes.typography}>
            {title}
        </Typography>
    );
};
