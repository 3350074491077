import React from 'react';
import { makeStyles, Box, Grid } from '@material-ui/core/';
import PropTypes from 'prop-types';
import NProgress from 'nprogress';

import { Title, Input } from '../';

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: '80px',
    },
}));

export const VehicleDetails = ({ inputFields, control }) => {
    const classes = useStyles();

    React.useEffect(() => {
        NProgress.done();
    });

    return (
        <Box className={classes.container}>
            <Title
                title='Tell us about your vehicle and service needs.'
                font='primary'
                variant='h6'
                bold='900'
                size='16px'
            />

            <div className={classes.inputs}>
                <Grid container>
                    {Object.keys(inputFields).map((name, index) => {
                        if (name === 'vin')
                            return (
                                <Grid xs={12} sm={6} item key={name + Math.random() * 999}>
                                    <Input
                                        name={name}
                                        label={inputFields[name].name}
                                        control={control}
                                    />
                                </Grid>
                            );
                        return (
                            <Grid xs={4} sm={2} item key={name + Math.random() * 999}>
                                <Input
                                    name={name}
                                    label={inputFields[name].name}
                                    control={control}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        </Box>
    );
};

VehicleDetails.propTypes = {
    inputFields: PropTypes.object.isRequired,
};
