import { put, call, takeLatest, select } from 'redux-saga/effects';

import { isLoadingAppAction } from '../actions/utilityAction';
import { snackbarAction } from '../actions/utilityAction';
import { apiCall } from '../http/apiCall';
import { tokenSelector } from '../selector';
import { APPOINTMENT, APPOINTMENT_BY_USER, USER } from '../../utility/urls';
import {
    APPOINTMENT_START,
    APPOINTMENT_COMPLETE,
    FETCH_USER_COMPLETE,
    UPDATE_APPOINTMENT_START,
    GET_APPOINTMENT_BY_USER_START,
    GET_APPOINTMENT_BY_USER_COMPLETE,
} from '../actionType';
import { throwError } from '../../utility/error';

function* fetchAppointment() {
    yield put(isLoadingAppAction(true));
    try {
        const token = yield select(tokenSelector);

        const userResult = yield call(apiCall, USER, null, token, 'GET');
        yield put({ type: FETCH_USER_COMPLETE, usersList: userResult.data });

        const appointmentResult = yield call(apiCall, APPOINTMENT, null, token, 'GET');
        yield put({ type: APPOINTMENT_COMPLETE, appointmentList: appointmentResult.data });
    } catch (error) {
        yield put(throwError(error));
    }
    yield put(isLoadingAppAction(false));
}

function* updateAppointment({ id, data }) {
    yield put(isLoadingAppAction(true));

    try {
        const token = yield select(tokenSelector);
        const result = yield call(apiCall, `${APPOINTMENT}/${id}`, data, token, 'PUT');

        yield put(
            snackbarAction({
                open: true,
                type: 'success',
                message: result.data.message,
            })
        );

        yield put({ type: APPOINTMENT_START });
    } catch (error) {
        yield put(throwError(error));
    }
    yield put(isLoadingAppAction(false));
}

function* getAppointmentByUser({ userId }) {
    try {
        const token = yield select(tokenSelector);
        const appointmentResult = yield call(
            apiCall,
            `${APPOINTMENT_BY_USER}/${userId}`,
            null,
            token,
            'GET'
        );

        yield put({
            type: GET_APPOINTMENT_BY_USER_COMPLETE,
            appointmentByUser: appointmentResult.data,
        });
    } catch (error) {
        yield put(throwError(error));
    }
}

export default function* appointmentWatch() {
    yield takeLatest(APPOINTMENT_START, fetchAppointment);
    yield takeLatest(UPDATE_APPOINTMENT_START, updateAppointment);
    yield takeLatest(GET_APPOINTMENT_BY_USER_START, getAppointmentByUser);
}
