import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteWithLayout, NotfoundPage as NotfoundPageView } from './components';
import {
    Home as HomeView,
    SignIn as SignInView,
    Main as MainView,
    ForgotPassword as ForgotPasswordView,
    Users as UsersView,
    Appointment as AppointmentView,
    ResetPassword as ResetPasswordView,
} from './views';
import { Main as MainLayout } from './layouts';

export default () => {
    return (
        <Switch>
            <Route exact path='/'>
                <HomeView />
            </Route>
            <Route exact path='/sign-in'>
                <SignInView />
            </Route>
            <Route exact path='/forgot-password'>
                <ForgotPasswordView />
            </Route>
            <Route exact path='/reset_password/:reset_token' component={ResetPasswordView} />
            <RouteWithLayout component={MainView} exact layout={MainLayout} path='/main' />
            <RouteWithLayout component={UsersView} exact layout={MainLayout} path='/users' />
            <Route exact path='/schedule-service' component={AppointmentView} />
            <Route path='*' component={NotfoundPageView} />
        </Switch>
    );
};
