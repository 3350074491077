import { combineReducers } from 'redux';

import { loginReducer } from './loginReducer';
import { utilityReducer } from './utilityReducer';
import { appointmentReducer } from './appointmentReducer';
import { licenseIDReducer } from './licenseIDReducer';
import { vehicleReducer } from './vehicleReducer';
import { userReducer } from './userReducer';

export default combineReducers({
    loginReducer,
    utilityReducer,
    appointmentReducer,
    licenseIDReducer,
    vehicleReducer,
    userReducer,
});
