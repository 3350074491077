import React from 'react';
import { makeStyles } from '@material-ui/core/';

import Autocomplete from 'react-google-autocomplete';

const useStyles = makeStyles((theme) => ({
    autocomplete: {
        height: '40px',
        paddingLeft: '16px',
        marginTop: '2px',
        boxSizing: 'border-box',
        width: '100%',
    },
}));

export const AutocompleteComp = ({ onPlaceSelected }) => {
    const classes = useStyles();

    return (
        <Autocomplete
            apiKey={process.env.REACT_APP_MAP_API}
            className={classes.autocomplete}
            onPlaceSelected={onPlaceSelected}
            componentRestrictions={{ country: 'us' }}
            options={{
                types: ['geocode', 'establishment'],
            }}
        />
    );
};
