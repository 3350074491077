import { get } from 'lodash';

/** Auth */
export const tokenSelector = (state) => get(state, 'loginReducer.token');
export const isResetTokenValidSelector = (state) => get(state, 'loginReducer.isResetTokenValid');
export const resetSnackbarSelector = (state) => get(state, 'loginReducer.resetSnackbar');

/** Utility  */
export const snackbarSelector = (state) => get(state, 'utilityReducer.snackbar');
export const isLoadingAppSelector = (state) => get(state, 'utilityReducer.isLoadingApp');

/** Appointment */
export const appointmentSelector = (state) => get(state, 'appointmentReducer.appointmentList');
export const scheduleListSelector = (state) => get(state, 'appointmentReducer.scheduleList');
export const getAppointmentByUserSelector = (state) =>
    get(state, 'appointmentReducer.appointmentByUser');

/** License */
export const licenseIDSelector = (state) => get(state, 'licenseIDReducer.licenseID');

/** Vehicle */
export const getUserVehicleSelector = (state) => get(state, 'vehicleReducer.userVehicleList');
export const singleVehicleSelector = (state) => get(state, 'vehicleReducer.singleVehicle');

/** User */
export const usersListSelector = (state) => get(state, 'appointmentReducer.usersList');
export const isLoadingUserDataSelector = (state) => get(state, 'userReducer.isLoadingUserData');
