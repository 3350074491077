import React, { useContext } from 'react';
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    makeStyles,
    Box,
} from '@material-ui/core/';

import { ScheduleData, RENTAL } from '../../context';
import { Title } from '../';

const useStyles = makeStyles((theme) => ({
    rental: {
        marginTop: theme.spacing(5),
    },
}));

export const RentalChoice = () => {
    const { state, dispatch } = useContext(ScheduleData);
    const classes = useStyles();

    const handleChange = (event) => {
        dispatch({ type: RENTAL, payload: event.target.value });
    };
    return (
        <Box className={classes.rental}>
            <Title
                title='Do you need a rental? Availability may vary.'
                font='primary'
                variant='h6'
                bold='900'
                size='16px'
            />

            <FormControl component='fieldset'>
                <RadioGroup
                    aria-label='rental'
                    name='rental1'
                    value={state.rentalStatus}
                    onChange={handleChange}
                >
                    <FormControlLabel
                        value={'true'}
                        control={<Radio />}
                        label='Yes, I need a rental'
                    />
                    <FormControlLabel
                        value={'false'}
                        control={<Radio />}
                        label='No, please pick up & deliver my vehicle from my designated location.'
                    />
                </RadioGroup>
            </FormControl>
        </Box>
    );
};
