import React from 'react';
import {
    makeStyles,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core/';

const useStyles = makeStyles((theme) => ({
    color: {
        color: '#616161',
    },
}));

export const SelectComp = ({ headline, inputText, label, value, options, handleChange }) => {
    const classes = useStyles();

    return (
        <>
            <Typography className={classes.color}>{headline}</Typography>
            <FormControl variant='outlined' className={inputText} size='small'>
                <InputLabel id='demo-simple-select-outlined-label'>{label}</InputLabel>
                <Select
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    value={value}
                    onChange={handleChange}
                    label={label}
                >
                    {options.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};
